import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Input from '../../../Components/Common/Input'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import InfoBox from '../../../Components/Common/InfoBox'
import FlightMap2D from '../../../Components/Common/FlightMap2D'
import { DateTime } from 'luxon'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'

const Throw = () => {
  const { throwStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()

  const { loading, discThrow } = throwStore

  const [deleteThrowId, setDeleteThrowId] = useState<number | null>(null)

  const openActionConfirmation = (id: number) => setDeleteThrowId(id)
  const closeActionConfirmation = () => setDeleteThrowId(null)
  
  useEffect(() => {
    const throwId = params?.throwId ?? null
    if (throwId) {
      throwStore.getThrow(Number(throwId))
    }
  }, [throwStore, params?.throwId])

  // Table state
  const [searchKey, setSearchKey] = useState<string>('')

  const clearSearchKey = () => setSearchKey('')

  const toMobileUser = () => navigate(`/users/mobile/${discThrow?.mobileUserId}`)
  const toEvent = () => navigate(`/events/${discThrow?.eventId}`)
  
  const archiveThrow = () => {
    if (params?.throwId && deleteThrowId) {
      throwStore.archiveThrow(
        Number(params?.throwId),
        closeActionConfirmation
      )
    }
  }

  const renderEventInformation = () => {
    if (discThrow?.eventId) {
      return (
        <>
          <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('event_information')}</Typography>
          <Box sx={styles.formContainer}>
            <Input
              label={t('event')}
              value={
                `${discThrow?.eventName}`?.trim() || '-'
              }
              onChange={() => {}}
              toEntity={toEvent}
              mb='1.5rem'
              disabled
            />
          </Box>
        </>
      )
    }
  }

  const renderMapSection = () => {
    if (
      discThrow?.startLatitude &&
      discThrow?.startLongitude &&
      discThrow?.endLatitude &&
      discThrow?.endLongitude &&
      discThrow?.flightData?.length > 2
    ) {
      return (
        <>
          <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('throw_on_map')}</Typography>
          <Box sx={styles.formContainer}>
            <FlightMap2D
              startLocation={{
                lat: Number(discThrow?.startLatitude),
                lng: Number(discThrow?.startLongitude)
              }}
              endLocation={{
                lat: Number(discThrow?.endLatitude),
                lng: Number(discThrow?.endLongitude)
              }}
              flightData={discThrow?.flightData || []}
              totalDuration={(discThrow?.flightTime || 0) + (discThrow?.rollTime || 0)}
            />
          </Box>
        </>
      )
    }
  }

  const renderConfirmationDialog = () => {
    if (deleteThrowId) {
      return (
        <ConfirmationDialog
          title={t('delete_throw')}
          content={t('delete_throw_confirmation')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archiveThrow}
          actionText={t('delete')}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mt='1rem' mb='1rem'>{t('general_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box display='flex' flexDirection='row' mb='2rem'>
            <InfoBox
              label={t('date')}
              value={
                discThrow?.createdAt ?
                  `${DateTime.fromISO(discThrow.createdAt).toFormat('dd.MM.yyyy')} ${t('at')} ${DateTime.fromISO(discThrow.createdAt).toFormat('HH.mm')}` :
                  '-'
              }
            />
            <InfoBox
              label={t('mode')}
              value={
                discThrow?.fixedDistanceCm ?
                `${t('net')} (${discThrow?.fixedDistanceCm} cm)` :
                t('field')
              }
            />
            <InfoBox
              label={t('shot_type')}
              value={discThrow?.shotType ? t(discThrow.shotType) : '-'}
              mr='0rem'
            />
          </Box>
          <Box display='flex' flexDirection='row' mb={discThrow?.note ? '2rem' : '1.5rem'}>
            <InfoBox
              label={t('mac')}
              value={discThrow?.hwUuid || '-'}
            />
            <InfoBox
              label={t('gps')}
              value={
                (!discThrow?.fixedDistanceCm && discThrow?.endLatitude && discThrow?.endLongitude) ?
                t('yes') :
                t('no')
              }
            />
            <InfoBox
              label={t('raw_data')}
              value={discThrow?.hasRawData ? t('yes') : t('no')}
              mr='0rem'
            />
          </Box>
          {discThrow?.note ? (
            <Box display='flex' flexDirection='row' mb='1.5rem'>
              <InfoBox
                label={t('notes')}
                value={discThrow?.note || '-'}
                mr='0rem'
              />
            </Box>
          ) : null}
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('user_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('user')}
            value={
              `${discThrow?.mobileUser?.firstName || ''} ${discThrow?.mobileUser?.lastName || ''} ${discThrow?.mobileUser?.email ? `(${discThrow.mobileUser?.email})` : ''}`?.trim() || '-'
            }
            onChange={() => {}}
            toEntity={toMobileUser}
            mb='1.5rem'
            disabled
          />
        </Box>
        {renderEventInformation()}
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('throw_basic_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('distance')}
              value={
                discThrow?.fixedDistanceCm ?
                `${discThrow?.fixedDistanceCm} cm` :
                `${discThrow?.distance || 0} m`
              }
              warning={!discThrow?.fixedDistanceCm && discThrow?.distance && !discThrow?.hasGps}
            />
            <InfoBox
              label={t('arm_speed')}
              value={`${discThrow?.armSpeed || 0} km/h`}
            />
            <InfoBox
              label={t('flight_speed')}
              value={`${discThrow?.flightSpeed || 0} km/h`}
              warning={Number(discThrow?.flightSpeed || 0) > Number(discThrow?.armSpeed || 0)}
              mr='0rem'
            />
          </Box>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('spin')}
              value={`${discThrow?.spin || 0} rpm`}
            />
            <InfoBox
              label={t('wobble')}
              value={`${discThrow?.wobble || 0} deg`}
            />
            <InfoBox
              label={t('speed_ratio')}
              value={`${(Number(discThrow?.flightSpeed || 0) / Number (discThrow?.armSpeed || 0) * 100)?.toFixed(0)} %`}
              warning={Number(discThrow?.flightSpeed || 0) / Number (discThrow?.armSpeed || 0) * 100 > 100}
              mr='0rem'
            />
          </Box>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('nose')}
              value={`${discThrow?.nose || 0} deg`}
            />
            <InfoBox
              label={t('hyzer')}
              value={`${discThrow?.hyzer || 0} deg`}
            />
            <InfoBox
              label={t('launch')}
              value={`${discThrow?.launch || 0} deg`}
              mr='0rem'
            />
          </Box>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('max_g')}
              value={`${discThrow?.topG || 0}`}
            />
            <InfoBox
              label={t('release')}
              value={`${discThrow?.quality || 0} ms`}
              mr='4rem'
            />
            <Box sx={{ width: '100%' }} />
          </Box>
        </Box>
        {renderMapSection()}
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('throw_advanced_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('flight_time')}
              value={`${((discThrow?.flightTime || 0) / 1000)?.toFixed(1)?.replace('.', ',')} s`}
            />
            <InfoBox
              label={t('roll_time')}
              value={`${((discThrow?.rollTime || 0) / 1000)?.toFixed(1)?.replace('.', ',')} s`}
              mr='4rem'
            />
            <Box sx={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mt='1.5rem' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
        <Button
          text={t('delete_throw')}
          onClick={openActionConfirmation}
          backgroundColor={Colors.brandPrimary}
          width='100%'
          mb='0.5rem'
          disabled={discThrow?.archivedAt}
        />
        </Box>
      </Box>
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(Throw)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '30rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem 2rem 0.5rem 2rem',
      xs: '1.5rem 1.5rem 0.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '20rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
} as const
