import { useRef, useEffect, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { formatNumber } from '../../Utils/transforms'
import { Colors } from '../../Utils/theme'

// Helper function to convert rem to pixels dynamically
const remToPixels = (rem: number): number => {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
  return rem * rootFontSize
}

// Types
interface DataPoint {
  label: string
  value: number
}

interface AnalyticsListChartProps {
  data?: DataPoint[]
  label?: string
  info?: string
  sx?: any
  mb?: number | string
  mt?: number | string
  mr?: number | string
  ml?: number | string
  width?: number | string
  height?: number | string
  backgroundColor?: string
}

interface TooltipData {
  label: string
  value: number
  x: number
  y: number
}

// Constants
const LabelPadding = 1
const LabelFontSize = 1.125
const LabelFontWeight = 500
const BoldLabelFontWeight = 600
const LabelColor = Colors.white
const BarBackgroundColor = Colors.brandSecondary
const TrackBackgroundColor = Colors.black15
const HoverBackgroundColor = Colors.lightPurple

// Helper function to measure text width
const measureText = (text: string, fontSize: number) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return 0
  
  // Measure with both weights to prevent layout shifts
  context.font = `${LabelFontWeight} ${fontSize}px sans-serif`
  const normalMetrics = context.measureText(text)
  context.font = `${BoldLabelFontWeight} ${fontSize}px sans-serif`
  const boldMetrics = context.measureText(text)
  
  return Math.max(normalMetrics.width, boldMetrics.width)
}

export default function AnalyticsListChart(props: AnalyticsListChartProps) {
  const { 
    data = [], 
    label,
    info,
  } = props

  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null)
  const [hoveredBar, setHoveredBar] = useState<string | null>(null)

  // Calculate total for percentage-based widths
  const total = useMemo(() => data.reduce((sum, d) => sum + d.value, 0), [data])

  // Calculate leftMargin based on longest label
  const leftMargin = useMemo(() => {
    const maxLabelWidth = Math.max(
      ...data.map(d => measureText(d.label, remToPixels(LabelFontSize))),
      0
    )
    return maxLabelWidth + remToPixels(1.875)
  }, [data])

  // Update dimensions when container size changes
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect()
        setDimensions({
          width,
          height: height - remToPixels(4.0625)
        })
      }
    }

    updateDimensions()
    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  const getStyles = () => {
    let containerStyles: any = styles.container
    if (props?.sx) containerStyles = { ...containerStyles, ...props.sx }
    if (props.mb) containerStyles = { ...containerStyles, marginBottom: props.mb }
    if (props.mt) containerStyles = { ...containerStyles, marginTop: props.mt }
    if (props.mr) {
      containerStyles = {
        ...containerStyles,
        mr: {
          xl: props.mr,
          xs: props.mr
        }
      }
    }
    if (props.ml) containerStyles = { ...containerStyles, marginLeft: props.ml }
    if (props?.width) containerStyles = { ...containerStyles, width: props.width }
    if (props?.height) containerStyles = { ...containerStyles, height: props.height }
    if (props?.backgroundColor) containerStyles = { ...containerStyles, backgroundColor: props.backgroundColor }
    return containerStyles
  }

  const ChartMargin = { top: 0, right: remToPixels(5), bottom: 0, left: leftMargin }

  // Ensure we have valid dimensions before calculating scales
  const boundedWidth = Math.max(0, dimensions.width || 0)
  const boundedHeight = Math.max(0, dimensions.height || 0)
  const xMax = Math.max(0, boundedWidth - ChartMargin.left - ChartMargin.right + remToPixels(LabelPadding))
  const yMax = Math.max(0, boundedHeight - ChartMargin.top - ChartMargin.bottom)

  // Using percentage-based domain instead of absolute values
  const xScale = scaleLinear({
    domain: [0, 100],
    range: [0, xMax],
    nice: true,
  })

  const yScale = scaleBand({
    domain: data.map(d => d.label),
    range: [0, yMax],
    padding: 0.66,
  })

  const renderChart = () => {
    if (!data?.length || boundedWidth === 0 || boundedHeight === 0) return null

    return (
      <Box sx={styles.chartContainer}>
        <svg width='100%' height='100%' viewBox={`0 0 ${boundedWidth} ${boundedHeight}`}>
          <Group left={ChartMargin.left} top={ChartMargin.top}>
            {data.map((d) => {
              const percentage = (d.value / total) * 100
              const barWidth = xScale(percentage)
              const barHeight = yScale.bandwidth()
              const barY = yScale(d.label)

              if (typeof barY !== 'number') return null

              const isHovered = hoveredBar === d.label

              return (
                <Group 
                  key={d.label}
                  onMouseEnter={(event) => {
                    const { clientX, clientY } = event
                    setTooltipData({
                      label: d.label,
                      value: d.value,
                      x: clientX,
                      y: clientY
                    })
                    setHoveredBar(d.label)
                  }}
                  onMouseLeave={() => {
                    setTooltipData(null)
                    setHoveredBar(null)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Bar
                    x={0}
                    y={barY}
                    width={xMax}
                    height={barHeight}
                    fill={TrackBackgroundColor}
                  />
                  <svg x={0} y={barY} width={barWidth} height={barHeight} style={{ overflow: 'visible' }}>
                    <Bar
                      x={0}
                      y={0}
                      width={barWidth}
                      height={barHeight}
                      fill={isHovered ? HoverBackgroundColor : BarBackgroundColor}
                      style={{
                        transition: 'fill 0.2s ease-out',
                        animation: 'growRight 1s ease-out forwards',
                      }}
                    />
                  </svg>
                  <text
                    x={-ChartMargin.left}
                    y={barY + barHeight / 2}
                    textAnchor='start'
                    dy={remToPixels(5/16)}
                    fill={LabelColor}
                    fontSize={remToPixels(LabelFontSize)}
                    fontWeight={isHovered ? BoldLabelFontWeight : LabelFontWeight}
                    style={{ 
                      fontFamily: 'inherit',
                      transition: 'font-weight 0.2s ease-out'
                    }}
                  >
                    {d.label}
                  </text>
                  <text
                    x={xMax + ChartMargin.right - remToPixels(LabelPadding)}
                    y={barY + barHeight / 2}
                    textAnchor='end'
                    dy={remToPixels(5/16)}
                    fill={LabelColor}
                    fontSize={remToPixels(LabelFontSize)}
                    fontWeight={isHovered ? BoldLabelFontWeight : LabelFontWeight}
                    style={{ 
                      fontFamily: 'inherit',
                      transition: 'font-weight 0.2s ease-out',
                      animation: 'fadeIn 0.5s ease-out forwards',
                      opacity: 0,
                      animationDelay: '0.5s'
                    }}
                  >
                    {formatNumber(d.value)}
                  </text>
                </Group>
              )
            })}
          </Group>
        </svg>
        {/*tooltipData && (
          <Fade in={true} timeout={200}>
            <Paper elevation={3} sx={styles.hoverCard}>
              <Typography variant='subtitle2' sx={styles.hoverTitle}>
                {tooltipData.value}
              </Typography>
            </Paper>
          </Fade>
        )*/}
      </Box>
    )
  }

  return (
    <Box ref={containerRef} sx={getStyles()}>
      <style>
        {`
          @keyframes growRight {
            from {
              transform: scaleX(0);
              transform-origin: left;
            }
            to {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
      <Box sx={styles.header}>
        <Box sx={styles.label}>{label}</Box>
        <Box sx={styles.info}>{info || '-'}</Box>
      </Box>
      {renderChart()}
    </Box>
  )
}

const styles = {
  container: {
    height: '26rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      xs: '1.125rem 1.5rem 0.625rem 1.5rem',
      // xs: '0.75rem 1rem 0rem 1rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    overflow: 'hidden',
  },
  chartContainer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  header: {
    mb: '0.5rem'
  },
  label: {
    fontSize: {
      xs: '1.125rem',
      //xs: '1rem'
    },
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
  },
  info: {
    fontSize: {
      xs: '0.875rem',
      // xs: '0.75rem'
    },
    fontWeight: 400,
    color: Colors.white,
  },
  hoverCard: {
    position: 'fixed',
    transform: 'translate(-50%, -100%)',
    px: '0.75rem',
    py: '0.5rem',
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  hoverTitle: {
    color: Colors.white,
    fontWeight: 700,
    textAlign: 'center',
  },
} as const