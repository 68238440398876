import { ISODate, MeasurementSystem } from './common'
import { MobileUser } from './mobileUser'

export enum Gender {
  All = 'all',
  Male = 'male',
  Female = 'female',
}

export enum SkillLevel {
  All = 'all',
  Pro = 'pro',
  Amateur = 'amateur',
}

export enum ThrowDataItem {
  'armSpeed' = 'armSpeed',
  'flightSpeed' = 'flightSpeed',
  'speedRatio' = 'speedRatio',
  'spin' = 'spin',
  'distance' = 'distance',
  'wobble' = 'wobble',
  'nose' = 'nose',
  'hyzer' = 'hyzer',
  'launch' = 'launch',
  'topG' = 'topG',
  'quality' = 'quality',
}

export enum EventStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface Event {
  id: number
  uuid: string
  name: string
  startsAt: ISODate
  endsAt: ISODate
  description: string
  descriptionHtml: string
  photo: string
  instructions: string
  instructionsBeforeEvent: string
  instructionsDuringEvent: string
  skillLevel: SkillLevel[]
  gender: Gender[]
  throwData: ThrowDataItem[]
  defaultSortField: ThrowDataItem
  publicLink: string
  place: string
  streetAddress: string
  postalCode: string
  city: string
  country: string
  longitude: number
  latitude: number
  status: EventStatus
  measurementSystem: MeasurementSystem
  hiddenInApp: boolean
  createdAt: ISODate
  updatedAt: ISODate
  publishedAt: ISODate
  archivedAt: ISODate

  activeThrowerToken: string
  activeThrowerId: number
  // The user who is registered as the current active thrower for the event.
  // Throws will be registered to this user,
  // even though technically they are thrown by the eventMobileUser.
  activeThrower: EventParticipant | null
  // Throws that are registered to eventMobileUser,
  // will display this name instead of the actual thrower's name
  customActiveThrowerName: string | null

  // This user (eventMobileUser) is a "middleman" for the event:
  // discs are paired with this user and all throw data is delivered via this user,
  // but registered to the actual thrower(activeThrower above)
  eventMobileUserId: number | null
  eventMobileUser: EventParticipant | null

  qrCodes: {
    participate: string
    activeThrower: string
  }
}

export type EventParticipant = Pick<
  MobileUser,
  'id' | 'uuid' | 'email' | 'firstName' | 'lastName' | 'gender' | 'skillLevel'
>
