import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class AnalyticsStore {
  rootStore

  // All analytics
  analytics: any = null
  // Skill level distribution
  skillLevelDistribution: any = []
  // Gender distribution
  genderDistribution = []
  // Handedness distribution
  handednessDistribution = []
  // Users by country
  countryDistribution = []
  // OS distribution (all versions)
  osDistribution = []
  // OS total distribution (by platform)
  osTotalDistribution = []
  // App version distribution
  appVersionDistribution = []
  // Firmware distribution
  firmwareDistribution = []
  // Disc ownership distribution
  discOwnershipDistribution = []
  // Disc pairings (last 12 months)
  discPairings = []
  // Throw type distribution
  throwTypeDistribution = []
  // Throw mode distribution
  throwModeDistribution = []
  // Throw firmware distribution
  throwFirmwareDistribution = []
  // Opened push notifications distribution
  readPushNotifications: any = []
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateAnalytics = (analytics: any) => { this.analytics = analytics }
  updateSkillLevelDistribution = (items: any) => { this.skillLevelDistribution = items }
  updateGenderDistribution = (items: any) => { this.genderDistribution = items }
  updateHandednessDistribution = (items: any) => { this.handednessDistribution = items }
  updateCountryDistribution = (items: any) => { this.countryDistribution = items }
  updateOsDistribution = (items: any) => { this.osDistribution = items }
  updateOsTotalDistribution = (items: any) => { this.osTotalDistribution = items }
  updateAppVersionDistribution = (items: any) => { this.appVersionDistribution = items }
  updateFirmwareDistribution = (items: any) => { this.firmwareDistribution = items }
  updateDiscOwnershipDistribution = (items: any) => { this.discOwnershipDistribution = items }
  updateDiscPairings = (items: any) => { this.discPairings = items }
  updateThrowTypeDistribution = (items: any) => { this.throwTypeDistribution = items }
  updateThrowModeDistribution = (items: any) => { this.throwModeDistribution = items }
  updateThrowFirmwareDistribution = (items: any) => { this.throwFirmwareDistribution = items }
  updateReadPushNotifications = (readPushNotifications: any) => { this.readPushNotifications = readPushNotifications }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateAnalytics(null)
    this.updateSkillLevelDistribution([])
    this.updateGenderDistribution([])
    this.updateHandednessDistribution([])
    this.updateCountryDistribution([])
    this.updateOsDistribution([])
    this.updateOsTotalDistribution([])
    this.updateAppVersionDistribution([])
    this.updateFirmwareDistribution([])
    this.updateDiscOwnershipDistribution([])
    this.updateDiscPairings([])
    this.updateThrowTypeDistribution([])
    this.updateThrowModeDistribution([])
    this.updateThrowFirmwareDistribution([])
    this.updateReadPushNotifications(null)
    this.updateLoading(false)
  }

  async getAnalytics(params?: any) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getAnalytics(params)
      if (response.ok) {
        const data = response?.data
        this.updateAnalytics(data)
        const skillLevelDistribution = Object.keys((data?.userSkillLevels || {})).filter((key) => key !== 'unknown').map((key) => ({
          label: i18n.t(key),
          value: data?.userSkillLevels?.[key]
        }))
        this.updateSkillLevelDistribution(skillLevelDistribution)
        const genderDistribution = Object.keys((data?.userGenders || {})).filter((key) => key !== 'unknown').map((key) => ({
          label: i18n.t(key),
          value: data?.userGenders?.[key]
        }))
        this.updateGenderDistribution(genderDistribution)
        const countryDistribution = Object.keys((data?.userCountries || {})).map((key) => ({
          label: key === 'unknown' ? i18n.t(key) : key,
          value: data?.userCountries?.[key] || 0,
        })).slice(0, 12)
        this.updateCountryDistribution(countryDistribution)
        const osDistribution = Object.keys((data?.osDistribution || {})).map((key) => ({
          label: i18n.t(key),
          value: data?.osDistribution?.[key] || 0,
        })).sort((a, b) => b.value - a.value).slice(0, 12)
        this.updateOsDistribution(osDistribution)
        const osTotalDistribution = Object.keys((data?.osTotalDistribution || {})).map((key) => ({
          label: i18n.t(key),
          value: data?.osTotalDistribution?.[key] || 0,
        })).sort((a, b) => b.value - a.value)
        this.updateOsTotalDistribution(osTotalDistribution)
        const appVersionDistribution = Object.keys((data?.appVersionDistribution || {})).map((key) => ({
          label: key,
          value: data?.appVersionDistribution?.[key] || 0,
        })).sort((a, b) => b.value - a.value).slice(0, 12)
        this.updateAppVersionDistribution(appVersionDistribution)
        const firmwareDistribution = Object.keys((data?.discFirmware || {})).filter((key) => key !== 'Unknown').map((key) => ({
          label: key,
          value: data?.discFirmware?.[key]
        })).sort((a, b) => b.value - a.value).slice(0, 12)
        this.updateFirmwareDistribution(firmwareDistribution)
        const discOwnershipDistribution = Object.keys((data?.discOwnership || {})).map((key) => ({
          label: `${key} ${i18n.t('pcs')}`,
          value: data?.discOwnership?.[key] || 0,
        })).sort((a, b) => b.value - a.value).slice(0, 3)
        this.updateDiscOwnershipDistribution(discOwnershipDistribution)
        const discPairings = Object.keys((data?.discPairings || {})).map((key) => ({
          label: key,
          value: data?.discPairings?.[key] || 0,
        }))
        this.updateDiscPairings(discPairings)
        const throwTypeDistribution = Object.keys((data?.throwShotTypes || {})).filter((key) => key !== 'unknown').map((key) => ({
          label: i18n.t(key),
          value: data?.throwShotTypes?.[key] || 0,
        }))
        this.updateThrowTypeDistribution(throwTypeDistribution)
        const throwModeDistribution = Object.keys((data?.throwModes || {})).map((key) => ({
          label: i18n.t(key),
          value: data?.throwModes?.[key] || 0,
        }))
        this.updateThrowModeDistribution(throwModeDistribution)
        const throwFirmwareDistribution = Object.keys((data?.throwFirmware || {})).map((key) => ({
          label: key === 'unknown' ? i18n.t(key) : `FW ${key}`,
          value: data?.throwFirmware?.[key]
        })).sort((a, b) => b.value - a.value).slice(0, 12)
        this.updateThrowFirmwareDistribution(throwFirmwareDistribution)
        const readPushNotifications = Object.keys((data?.readPushNotifications || {})).map((key) => ({
          label: key?.length > 30 ? `${key.slice(0, 30)}...` : key,
          value: data?.readPushNotifications?.[key] || 0,
        })).sort((a, b) => b.value - a.value).slice(0, 12)
        this.updateReadPushNotifications(readPushNotifications)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
