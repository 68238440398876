import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { formatNumber } from '../../Utils/transforms'
import { Colors } from '../../Utils/theme'

export default function UserAnalyticsGroup(props: any) {
  const {
    activeUserCount,
    archivedUserCount,
    usersWithDiscCount,
    pdgaMemberCount,
    newUsersCount,
  } = props

  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.highlightItem}>
        <Box sx={styles.highlightLabel}>{t('users')}</Box>
        <Box sx={styles.highlightValue}>{formatNumber(activeUserCount)}</Box>
      </Box>
      <Box sx={styles.item}>
        <Box sx={styles.label}>{t('users_with_paired_disc')}</Box>
        <Box sx={styles.value}>{formatNumber(usersWithDiscCount)}</Box>
      </Box>
      <Box sx={styles.item}>
        <Box sx={styles.label}>{t('archived_users')}</Box>
        <Box sx={styles.value}>{formatNumber(archivedUserCount)}</Box>
      </Box>
      <Box sx={styles.item}>
        <Box sx={styles.label}>{t('pdga_members')}</Box>
        <Box sx={styles.value}>{formatNumber(pdgaMemberCount)}</Box>
      </Box>
      <Box sx={styles.borderlessItem}>
        <Box sx={styles.label}>{t('new_users_in_period')}</Box>
        <Box sx={styles.value}>{formatNumber(newUsersCount)}</Box>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    height: '7.25rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.625rem',
    mt: '1.5rem'
  },
  highlightItem: {
    minWidth: '20%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `3px solid ${Colors.background}`,
  },
  item: {
    height: '7.25rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRight: `3px solid ${Colors.background}`,
  },
  borderlessItem: {
    height: '7.25rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  highlightLabel: {
    fontSize: {
      xl: '1rem',
      xs: '0.875rem'
    },
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
    mt: '1rem',
  },
  highlightValue: {
    fontSize: {
      xl: '2.5rem',
      xs: '2rem'
    },
    fontWeight: 500,
    color: Colors.white,
  },
  label: {
    fontSize: {
      xl: '0.875rem',
      xs: '0.875rem'
    },
    fontWeight: 400,
    color: Colors.white,
    mt: '1rem',
    mb: '0.325rem'
  },
  value: {
    fontSize: {
      xl: '2rem',
      xs: '1.875rem'
    },
    fontWeight: 500,
    color: Colors.white,
    mb: '0.5rem'
  },
  diff: {
    fontSize: {
      xl: '1rem',
      xs: '0.875rem'
    },
    fontWeight: 600,
    color: Colors.lightPurple,
    mb: '1.5rem'
  }
} as const
