import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import Box from '@mui/material/Box'
import { LinePath, AreaClosed } from '@visx/shape'
import { scaleLinear, scalePoint } from '@visx/scale'
import { AxisBottom, AxisLeft, AxisScale, TickRendererProps } from '@visx/axis'
import { Tooltip as VisxTooltip, defaultStyles } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { GridRows, GridColumns } from '@visx/grid'
import { curveLinear } from '@visx/curve'
import { useSprings, animated, to } from '@react-spring/web'
import { Colors } from '../../Utils/theme'
import { formatNumber } from '../../Utils/transforms'

// Helper function to convert rem to pixels dynamically
const remToPixels = (rem: number): number => {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
  return rem * rootFontSize
}

type MonthlyData = {
  monthYear: string
  total: number
  byFirmware: Record<string, number>
  byMode: Record<string, number>
}

interface ThrowChartProps {
  data?: Record<
    string,
    {
      total: number
      byFirmware: Record<string, number>
      byMode: Record<string, number>
    }
  >
  label?: string
  info?: string
  sx?: any
  mb?: number | string
  mt?: number | string
  mr?: number | string
  ml?: number | string
  width?: number | string
  height?: number | string
  backgroundColor?: string
}

const DefaultMargin = { top: 40, right: 20, bottom: 50, left: 60 }

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      xs: '1.125rem 1.5rem 2.5rem 1.5rem',
      // xs: '0.75rem 1rem 2rem 1rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    overflow: 'hidden',
  },
  chartContainer: {
    flexGrow: 1,
    width: '100%',
    position: 'relative' as const,
    // height: '22rem',
  },
  header: {
    mb: '0.5rem',
  },
  label: {
    fontSize: {
      xs: '1.125rem',
      // xs: '1rem'
    },
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase' as const,
  },
  info: {
    fontSize: {
      xs: '0.875rem',
      // xs: '0.75rem'
    },
    fontWeight: 400,
    color: Colors.white,
  },
  tooltip: {
    ...defaultStyles,
    position: 'fixed',
    transform: 'translate(calc(-50% + 1rem), calc(-100% + 2rem))',
    background: Colors.brandPrimary,
    color: Colors.white,
    padding: '1rem 0rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    width: '12rem',
    textAlign: 'center' as const,
    zIndex: 1000,
    pointerEvents: 'none' as const,
  } as React.CSSProperties,
  tooltipRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 0.5,
    width: '100%',
    px: 0.5,
  } as React.CSSProperties,
  tooltipHeader: {
    paddingBottom: '0.75rem',
    borderBottom: `1px solid ${Colors.white20}`,
    marginBottom: '0.75rem',
  } as React.CSSProperties,
  tooltipTitle: {
    fontSize: '1rem',
  } as React.CSSProperties,
  tooltipContent: {
    marginTop: '0.5rem',
    fontSize: '1rem',
    padding: '0.125rem 1rem 0rem 1rem',
  } as React.CSSProperties,
  tooltipData: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    textAlign: 'center' as const,
  } as React.CSSProperties,
  tooltipDataLabel: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.white,
    marginBottom: '0.5rem',
  } as React.CSSProperties,
  tooltipDataValue: {
    fontSize: '1rem',
    fontWeight: 400,
  } as React.CSSProperties,
} as const

const AnimatedLinePath = animated(LinePath)
const AnimatedAreaClosed = animated(AreaClosed)

const AnimatedTick: React.FC<{
  x: number
  y: number
  formattedValue?: string | number
  innerHeight: number
}> = ({ x, y, formattedValue, innerHeight }) => {
  const springProps = useSprings(1, [
    {
      from: { y: y + innerHeight / 2, opacity: 0 },
      to: { y, opacity: 1 },
      config: { tension: 120, friction: 14 },
    },
  ])

  return (
    <animated.g
      transform={to(
        [springProps[0].y],
        (yVal) => `translate(${x}, ${yVal})`
      )}
    >
      <animated.text
        style={{
          fill: Colors.white,
          fontSize: '0.875rem',
          textAnchor: 'end',
          opacity: springProps[0].opacity,
          dominantBaseline: 'middle',
        }}
      >
        {formattedValue?.toString()}
      </animated.text>
    </animated.g>
  )
}

const ThrowChart: React.FC<ThrowChartProps> = ({
  data,
  label,
  info,
  sx,
  mb,
  mt,
  mr,
  ml,
  width,
  height = '30rem',
  backgroundColor,
}) => {
  const [tooltipData, setTooltipData] = useState<MonthlyData | null>(null)
  const [tooltipLeft, setTooltipLeft] = useState(0)
  const [tooltipTop, setTooltipTop] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const [chartWidth, setChartWidth] = useState(0)
  const [dimensionsReady, setDimensionsReady] = useState(false)
  const isInitialMount = useRef(true)
  const prevDataRef = useRef<string>('')
  const [hoveredX, setHoveredX] = useState<number | null>(null)
  const [hoveredY, setHoveredY] = useState<number | null>(null)

  const currentDataString = useMemo(() => JSON.stringify(data), [data])

  // Use a ResizeObserver to track container size changes and update chartWidth.
  useEffect(() => {
    if (!containerRef.current) return

    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) return
      const entry = entries[0]
      if (entry) {
        setChartWidth(entry.contentRect.width)
        setDimensionsReady(true)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const getStyles = () => {
    let containerStyles: any = styles.container
    if (sx) containerStyles = { ...containerStyles, ...sx }
    if (mb) containerStyles = { ...containerStyles, marginBottom: mb }
    if (mt) containerStyles = { ...containerStyles, marginTop: mt }
    if (mr) {
      containerStyles = {
        ...containerStyles,
        mr: {
          xl: mr,
          xs: mr,
        },
      }
    }
    if (ml) containerStyles = { ...containerStyles, marginLeft: ml }
    if (width) containerStyles = { ...containerStyles, width }
    if (height) containerStyles = { ...containerStyles, height }
    if (backgroundColor) containerStyles = { ...containerStyles, backgroundColor }
    return containerStyles
  }

  const margin = DefaultMargin
  // Aspect ratio approach and max chart height
  const aspectRatio = 0.6
  const MAX_CHART_HEIGHT = remToPixels(400 / 16)
  const chartHeight = Math.min(chartWidth * aspectRatio, MAX_CHART_HEIGHT)

  const innerWidth = chartWidth - margin.left - margin.right
  const innerHeight = chartHeight - margin.top - margin.bottom

  const chartData = useMemo(() => {
    if (!data) return []

    return Object.entries(data)
      .map(([monthYear, values]) => ({
        monthYear,
        total: values.total,
        byFirmware: values.byFirmware,
        byMode: values.byMode,
      }))
      .sort((a, b) => {
        const [aMonth, aYear] = a.monthYear.split('/')
        const [bMonth, bYear] = b.monthYear.split('/')
        if (aYear !== bYear) return Number(aYear) - Number(bYear)
        return Number(aMonth) - Number(bMonth)
      })
  }, [data])

  const xScale = useMemo(
    () =>
      scalePoint({
        domain: chartData.map((d) => d.monthYear),
        range: [0, innerWidth],
        padding: 0.1,
      }),
    [chartData, innerWidth]
  )

  const yScale = useMemo(() => {
    const maxY = Math.max(...chartData.map((d) => d.total), 0)
    return scaleLinear({
      domain: [0, maxY * 1.1],
      range: [innerHeight, 0],
      nice: true,
    })
  }, [chartData, innerHeight])

  const getScaledX = useCallback(
    (d: any) => xScale(d.monthYear)!,
    [xScale]
  )
  const getScaledY = useCallback((d: any) => yScale(d.total), [yScale])

  const getPathLength = useCallback(() => {
    if (!dimensionsReady) return 0
    let length = 0
    for (let i = 1; i < chartData.length; i++) {
      const x1 = xScale(chartData[i - 1].monthYear)!
      const y1 = yScale(chartData[i - 1].total)
      const x2 = xScale(chartData[i].monthYear)!
      const y2 = yScale(chartData[i].total)
      const dx = x2 - x1
      const dy = y2 - y1
      length += Math.sqrt(dx * dx + dy * dy)
    }
    return length
  }, [dimensionsReady, xScale, yScale, chartData])

  const pathLength = getPathLength()
  const springs = useSprings(1, [
    {
      from: { pathLength },
      to: { pathLength: 0 },
      reset: isInitialMount.current || currentDataString !== prevDataRef.current,
      config: { tension: 80, friction: 20 },
    },
  ])

  useEffect(() => {
    if (!dimensionsReady) return
    if (isInitialMount.current || currentDataString !== prevDataRef.current) {
      prevDataRef.current = currentDataString
      isInitialMount.current = false
    }
  }, [dimensionsReady, currentDataString])

  const handleTooltip = (event: React.MouseEvent<SVGRectElement>) => {
    if (!containerRef.current) return
    const { left, top } = containerRef.current.getBoundingClientRect()
    const point = localPoint(event)
    if (!point) return

    // Calculate mouse position relative to the SVG's inner chart area
    const xPos = point.x - margin.left
    const yPos = point.y - margin.top

    // Store the hovered cursor position
    setHoveredX(xPos)
    setHoveredY(yPos)

    const x = point.x - margin.left
    const closest = chartData.reduce((prev, curr) => {
      const currX = xScale(curr.monthYear) || 0
      const prevX = xScale(prev.monthYear) || 0
      return Math.abs(currX - x) < Math.abs(prevX - x) ? curr : prev
    })

    setTooltipData(closest)
    setTooltipLeft(left + (xScale(closest.monthYear) || 0) + margin.left)
    setTooltipTop(top + margin.top + yScale(closest.total))
  }

  return (
    <Box ref={containerRef} sx={getStyles()}>
      <Box sx={styles.header}>
        <Box sx={styles.label}>{label}</Box>
        <Box sx={styles.info}>{info || '-'}</Box>
      </Box>
      <Box sx={styles.chartContainer}>
        {dimensionsReady && chartWidth > 0 && (
          <svg
            width={chartWidth}
            height={chartHeight}
            style={{ overflow: 'visible' }}
          >
            <defs>
              <linearGradient
                id='area-gradient'
                gradientUnits='userSpaceOnUse'
                x1={0}
                y1={yScale(0)}
                x2={0}
                y2={yScale(yScale.domain()[1])}
              >
                <stop offset='0%' stopColor='#4C0150' stopOpacity={0.15} />
                <stop offset='100%' stopColor='#4C0150' stopOpacity={0.85} />
              </linearGradient>
            </defs>
            <g transform={`translate(${margin.left},${margin.top})`}>
              <GridRows
                scale={yScale}
                width={innerWidth}
                height={innerHeight}
                stroke='rgba(255,255,255,0.1)'
                strokeDasharray='2,2'
              />
              <GridColumns
                scale={xScale}
                width={innerWidth}
                height={innerHeight}
                stroke='rgba(255,255,255,0.1)'
                strokeDasharray='2,2'
              />
              <AnimatedAreaClosed
                data={chartData}
                x={getScaledX}
                y={getScaledY}
                yScale={yScale}
                fill='url(#area-gradient)'
                stroke='none'
                curve={curveLinear}
                style={{
                  opacity: springs[0].pathLength.to(
                    (pl) => 1 - pl / pathLength
                  ),
                }}
              />
              <AnimatedLinePath
                data={chartData}
                x={getScaledX}
                y={getScaledY}
                stroke='#4C0150'
                strokeWidth={2}
                curve={curveLinear}
                style={{
                  strokeDasharray: pathLength,
                  strokeDashoffset: springs[0].pathLength,
                }}
              />
              <AxisBottom
                top={innerHeight}
                scale={xScale}
                tickFormat={(value) => {
                  const [month, year] = value.split('/')
                  return `${month}/${year.slice(-2)}`
                }}
                hideTicks
                tickLabelProps={(tick) => ({
                  fill: Colors.white,
                  fontSize: '0.875rem',
                  textAnchor: 'middle' as const,
                  dy: '0.25rem',
                  fontWeight: tick === tooltipData?.monthYear ? 'bold' : 'normal',
                })}
                strokeWidth={1}
                stroke={Colors.brandPrimary}
              />
              <AxisLeft
                scale={yScale}
                tickFormat={(d) =>
                  d.valueOf() === 0 ? '0' : formatNumber(d.valueOf()).toString()
                }
                tickComponent={(props: TickRendererProps) => (
                  <AnimatedTick
                    x={props.x ?? 0}
                    y={props.y ?? 0}
                    formattedValue={props.formattedValue}
                    innerHeight={yScale.range()[0]}
                  />
                )}
                hideTicks
                hideAxisLine
              />
              <rect
                width={innerWidth}
                height={innerHeight}
                fill='transparent'
                onMouseMove={handleTooltip}
                onMouseLeave={() => {
                  setTooltipData(null)
                  setHoveredX(null)
                  setHoveredY(null)
                }}
              />
              {hoveredX !== null && hoveredY !== null && (
                <>
                  <line
                    x1={hoveredX}
                    x2={hoveredX}
                    y1={0}
                    y2={innerHeight}
                    stroke='rgba(255,255,255,0.2)'
                    strokeDasharray='2,2'
                    pointerEvents='none'
                  />
                  <line
                    x1={0}
                    x2={innerWidth}
                    y1={hoveredY}
                    y2={hoveredY}
                    stroke='rgba(255,255,255,0.2)'
                    strokeDasharray='2,2'
                    pointerEvents='none'
                  />
                </>
              )}
            </g>
          </svg>
        )}
        {tooltipData && (
          <VisxTooltip style={styles.tooltip} top={tooltipTop} left={tooltipLeft}>
            <div>
              <div style={styles.tooltipHeader}>
                <strong style={styles.tooltipTitle}>
                  {formatNumber(tooltipData.total)} kpl
                </strong>
              </div>
              <div style={styles.tooltipRow}>
                <div style={styles.tooltipData}>
                  <div style={styles.tooltipDataLabel}>Net</div>
                  <div style={styles.tooltipDataValue}>
                    {formatNumber(tooltipData?.byMode?.['net'])} kpl
                  </div>
                </div>
                <div style={styles.tooltipData}>
                  <div style={styles.tooltipDataLabel}>Field</div>
                  <div style={styles.tooltipDataValue}>
                    {formatNumber(tooltipData?.byMode?.['field'])} kpl
                  </div>
                </div>
              </div>
            </div>
          </VisxTooltip>
        )}
      </Box>
    </Box>
  )
}

export default ThrowChart
