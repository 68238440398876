import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react'
import Box from '@mui/material/Box'
import { BarStack } from '@visx/shape'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { AxisBottom, AxisLeft, TickRendererProps } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Tooltip as VisxTooltip, defaultStyles } from '@visx/tooltip'
import { GridRows, GridColumns } from '@visx/grid'
import { animated, useSpring, to } from '@react-spring/web'
import { formatNumber } from '../../Utils/transforms'
import { Colors } from '../../Utils/theme'

type ChartDataKeys = 'android' | 'ios' | 'unknown'
const Keys: ChartDataKeys[] = ['android', 'ios', 'unknown']

interface MonthlyUserData {
  total: string
  android: string
  ios: string
  unknown: string
}

interface ChartDataItem {
  monthYear: string
  android: number
  ios: number
  unknown: number
}

interface AnalyticsUserChartProps {
  data?: Record<string, MonthlyUserData>
  label?: string
  info?: string
  sx?: any
  mb?: number | string
  mt?: number | string
  mr?: number | string
  ml?: number | string
  width?: number | string
  height?: number | string
  backgroundColor?: string
}

// Default chart margins
const DefaultMargin = { top: 40, right: 20, bottom: 10, left: 40 }

// Styles
const chartStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      xs: '1.125rem 1.5rem 2.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    overflow: 'visible',
    position: 'relative' as const,
  },
  tooltip: {
    ...defaultStyles,
    position: 'fixed',
    transform: 'translate(-50%, calc(-100% + 2rem))',
    background: Colors.brandPrimary,
    color: Colors.white,
    padding: '1rem 0rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    width: '12rem',
    textAlign: 'center',
    zIndex: 1000,
    pointerEvents: 'none',
  } as React.CSSProperties,
  tooltipRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 0.5,
    width: '100%',
    px: 0.5
  } as React.CSSProperties,
  tooltipHeader: {
    paddingBottom: '0.75rem',
    borderBottom: `1px solid ${Colors.white20}`,
    marginBottom: '0.75rem',
  } as React.CSSProperties,
  tooltipTitle: {
    fontSize: '1rem',
  } as React.CSSProperties,
  tooltipContent: {
    marginTop: '0.5rem',
    fontSize: '1rem',
    padding: '0.125rem 1rem 0rem 1rem',
  } as React.CSSProperties,
  tooltipData: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  } as React.CSSProperties,
  tooltipDataLabel: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.white80,
    marginBottom: '0.5rem',
  } as React.CSSProperties,
  tooltipDataValue: {
    fontSize: '1rem',
    fontWeight: 400
  } as React.CSSProperties,
  header: {
    mb: '0.5rem',
  },
  label: {
    fontSize: {
      xs: '1.125rem',
    },
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase' as const,
  },
  info: {
    fontSize: {
      xs: '0.875rem',
    },
    fontWeight: 400,
    color: Colors.white,
  },
  chartContainer: {
    flexGrow: 1,
    width: '100%',
    position: 'relative' as const,
  },
}

// Helper to map the key to a display-friendly string
function getDisplayKeyName(key: ChartDataKeys) {
  switch (key) {
    case 'ios':
      return 'iOS'
    case 'android':
      return 'Android'
    case 'unknown':
      return 'Ei tiedossa'
    default:
      return key
  }
}

// Animated Tick (y-axis)
interface AnimatedTickProps {
  x: number
  y: number
  formattedValue?: string | number
  innerHeight: number
}
const AnimatedTick: React.FC<AnimatedTickProps> = ({
  x,
  y,
  formattedValue,
  innerHeight,
}) => {
  const springProps = useSpring({
    from: { y: y + innerHeight / 2, opacity: 0 },
    to: { y, opacity: 1 },
    config: { tension: 120, friction: 14 },
  })

  return (
    <animated.g
      transform={to([springProps.y], (yVal) => `translate(${x}, ${yVal})`)}
    >
      <animated.text
        style={{
          fill: Colors.white,
          fontSize: '0.875rem',
          textAnchor: 'end',
          opacity: springProps.opacity,
          dominantBaseline: 'middle',
        }}
      >
        {formattedValue?.toString()}
      </animated.text>
    </animated.g>
  )
}

const AnalyticsUserChart: React.FC<AnalyticsUserChartProps> = ({
  data,
  label,
  info,
  sx,
  mb,
  mt,
  mr,
  ml,
  width,
  backgroundColor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [chartWidth, setChartWidth] = useState(0)
  const [dimensionsReady, setDimensionsReady] = useState(false)

  const aspectRatio = 0.6
  const MAX_CHART_HEIGHT = 480
  const chartHeight = Math.min(chartWidth * aspectRatio, MAX_CHART_HEIGHT)

  // Tooltip data
  const [tooltipData, setTooltipData] = useState<{
    monthYear: string
    key: ChartDataKeys
    android: number
    ios: number
    unknown: number
  } | null>(null)
  const [tooltipLeft, setTooltipLeft] = useState(0)
  const [tooltipTop, setTooltipTop] = useState(0)

  // Observe container width changes
  useEffect(() => {
    if (!containerRef.current) return

    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) return
      const entry = entries[0]
      if (entry) {
        setChartWidth(entry.contentRect.width)
        setDimensionsReady(true)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const getStyles = useCallback(() => {
    let containerStyles: any = { ...chartStyles.container }
    if (sx) containerStyles = { ...containerStyles, ...sx }
    if (mb) containerStyles = { ...containerStyles, marginBottom: mb }
    if (mt) containerStyles = { ...containerStyles, marginTop: mt }
    if (mr) {
      containerStyles = {
        ...containerStyles,
        mr: {
          xl: mr,
          xs: mr,
        },
      }
    }
    if (ml) containerStyles = { ...containerStyles, marginLeft: ml }
    if (width) containerStyles = { ...containerStyles, width }
    if (backgroundColor) containerStyles = { ...containerStyles, backgroundColor }
    return containerStyles
  }, [sx, mb, mt, mr, ml, width, backgroundColor])

  const chartData = useMemo<ChartDataItem[]>(() => {
    if (!data) return []
    return Object.entries(data)
      .map(([monthYear, values]) => ({
        monthYear,
        android: parseInt(values.android, 10) || 0,
        ios: parseInt(values.ios, 10) || 0,
        unknown: parseInt(values.unknown, 10) || 0,
      }))
      .sort((a, b) => {
        const [aMonth, aYear] = a.monthYear.split('/')
        const [bMonth, bYear] = b.monthYear.split('/')
        if (aYear !== bYear) return Number(aYear) - Number(bYear)
        return Number(aMonth) - Number(bMonth)
      })
  }, [data])

  // Basic dimensions
  const margin = DefaultMargin
  const innerWidth = chartWidth - margin.left - margin.right
  const innerHeight = chartHeight - margin.top - margin.bottom

  // X-scale
  const xScale = useMemo(() => {
    return scaleBand<string>({
      domain: chartData.map((d) => d.monthYear),
      range: [0, innerWidth],
      paddingInner: 0.7,
      paddingOuter: 0.45,
      align: 0.5,
    })
  }, [chartData, innerWidth])

  // Max value (android + ios + unknown)
  const maxValue = useMemo(() => {
    return Math.max(...chartData.map((d) => d.android + d.ios + d.unknown), 0)
  }, [chartData])

  // Y-scale
  const yScale = useMemo(() => {
    return scaleLinear<number>({
      domain: [0, maxValue],
      range: [innerHeight, 0],
      nice: true,
    })
  }, [maxValue, innerHeight])

  const colorScale = useMemo(() => {
    return scaleOrdinal<ChartDataKeys, string>({
      domain: Keys,
      range: ['#4C0150', '#FBCCEB', '#FF76D0'],
    })
  }, [])

  const handleMouseMove = useCallback(
    (
      event: React.MouseEvent<SVGRectElement>,
      barKey: ChartDataKeys,
      androidVal: number,
      iosVal: number,
      unknownVal: number,
      monthYear: string
    ) => {
      if (!containerRef.current) return
      const { left, top } = containerRef.current.getBoundingClientRect()
      const point = localPoint(event)
      if (!point) return

      setTooltipData({
        monthYear,
        key: barKey,
        android: androidVal,
        ios: iosVal,
        unknown: unknownVal,
      })
      setTooltipLeft(left + point.x)
      setTooltipTop(top + point.y - 20)
    },
    []
  )

  const handleMouseLeave = useCallback(() => {
    setTooltipData(null)
  }, [])

  if (!dimensionsReady || chartData.length === 0) {
    // Either no data or we haven't measured yet
    return (
      <Box ref={containerRef} sx={getStyles()}>
        <Box sx={chartStyles.header}>
          <Box sx={chartStyles.label}>{label}</Box>
          <Box sx={chartStyles.info}>{info || '-'}</Box>
        </Box>
      </Box>
    )
  }

  // If width or height is negative or zero, bail out
  if (innerWidth <= 0 || innerHeight <= 0) {
    return null
  }

  // Updated total to also include `unknown`
  const getTotal = (item: { android: number; ios: number; unknown: number }) =>
    item.android + item.ios + item.unknown

  return (
    <Box ref={containerRef} sx={getStyles()}>
      {/* Header area */}
      <Box sx={chartStyles.header}>
        <Box sx={chartStyles.label}>{label}</Box>
        <Box sx={chartStyles.info}>{info || '-'}</Box>
      </Box>
      {/* Legend in top-right corner */}
      <Box
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        {Keys.map((key) => {
          const color = colorScale(key)
          return (
            <Box
              key={key}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                cursor: 'default',
              }}
            >
              <Box
                component='span'
                sx={{
                  display: 'inline-block',
                  width: '0.75rem',
                  height: '0.75rem',
                  borderRadius: '50%',
                  backgroundColor: color,
                  mt: '0.125rem',
                  mr: '0.5rem',
                }}
              />
              <Box
                component='span'
                sx={{
                  fontSize: '1rem',
                  color: Colors.white,
                }}
              >
                {getDisplayKeyName(key)}
              </Box>
            </Box>
          )
        })}
      </Box>
      {/* Chart area */}
      <Box sx={chartStyles.chartContainer}>
        <svg
          width={chartWidth}
          height={chartHeight}
          style={{ overflow: 'visible' }}
        >
          <g transform={`translate(${margin.left},${margin.top})`}>
            {/* Grid Rows / Columns */}
            <GridRows
              scale={yScale}
              width={innerWidth}
              height={innerHeight}
              stroke='rgba(255,255,255,0.1)'
              strokeDasharray='2,2'
            />
            <GridColumns
              scale={xScale}
              width={innerWidth}
              height={innerHeight}
              stroke='rgba(255,255,255,0.1)'
              strokeDasharray='2,2'
            />

            {/* Axis Left (with animated ticks) */}
            <AxisLeft
              scale={yScale}
              tickFormat={(d) =>
                d === 0 ? '0' : formatNumber(d as number).toString()
              }
              hideTicks
              hideAxisLine
              tickComponent={(props: TickRendererProps) => (
                <AnimatedTick
                  x={props.x ?? 0}
                  y={props.y ?? 0}
                  formattedValue={props.formattedValue}
                  innerHeight={yScale.range()[0]}
                />
              )}
            />
            {/* Axis Bottom */}
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              hideTicks
              strokeWidth={1}
              stroke={Colors.brandPrimary}
              tickLabelProps={() => ({
                fill: Colors.white,
                fontSize: '0.875rem',
                textAnchor: 'middle' as const,
                dy: '0.25rem',
              })}
              tickFormat={(value) => {
                // e.g. '01/25' or '12/24'
                const [mm, yyyy] = value.split('/')
                return `${mm}/${yyyy.slice(-2)}`
              }}
            />
            {/* BarStack */}
            <BarStack<ChartDataItem, ChartDataKeys>
              data={chartData}
              keys={Keys}
              x={(d) => d.monthYear}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => {
                    const barX = bar.x
                    const barY = bar.y
                    const barWidth = bar.width
                    const barHeight = bar.height
                    if (!barX || !barY || !barWidth || !barHeight) return null

                    // Extract values from the data
                    const { android, ios, unknown, monthYear } = bar.bar.data

                    return (
                      <animated.rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        fill={bar.color}
                        onMouseMove={(event) =>
                          handleMouseMove(
                            event,
                            bar.key,
                            android,
                            ios,
                            unknown,
                            monthYear
                          )
                        }
                        onMouseLeave={handleMouseLeave}
                      />
                    )
                  })
                )
              }
            </BarStack>
          </g>
        </svg>
        {/* Tooltip */}
        {tooltipData && (
          <VisxTooltip style={chartStyles.tooltip} top={tooltipTop} left={tooltipLeft}>
            <div>
              <div style={chartStyles.tooltipHeader}>
                <strong style={chartStyles.tooltipTitle}>
                  {formatNumber(
                    getTotal({
                      android: tooltipData.android,
                      ios: tooltipData.ios,
                      unknown: tooltipData.unknown,
                    })
                  )}{' '}
                  kpl
                </strong>
              </div>
              {/* --- 4) Now show 3 columns in the tooltip --- */}
              <div style={chartStyles.tooltipRow}>
                <div style={chartStyles.tooltipData}>
                  <div
                    style={{
                      ...chartStyles.tooltipDataLabel,
                      color:
                        tooltipData.key === 'android'
                          ? Colors.white
                          : Colors.white80,
                    }}
                  >
                    Android
                  </div>
                  <div
                    style={{
                      ...chartStyles.tooltipDataValue,
                      fontWeight: tooltipData.key === 'android' ? 600 : 400,
                    }}
                  >
                    {formatNumber(tooltipData.android)} kpl
                  </div>
                </div>
                <div style={chartStyles.tooltipData}>
                  <div
                    style={{
                      ...chartStyles.tooltipDataLabel,
                      color:
                        tooltipData.key === 'ios'
                          ? Colors.white
                          : Colors.white80,
                    }}
                  >
                    iOS
                  </div>
                  <div
                    style={{
                      ...chartStyles.tooltipDataValue,
                      fontWeight: tooltipData.key === 'ios' ? 600 : 400,
                    }}
                  >
                    {formatNumber(tooltipData.ios)} kpl
                  </div>
                </div>
              </div>
              <div style={{ ...chartStyles.tooltipData, marginTop: '1rem' }}>
                <div
                  style={{
                    ...chartStyles.tooltipDataLabel,
                    color:
                      tooltipData.key === 'unknown'
                        ? Colors.white
                        : Colors.white80,
                  }}
                >
                  Ei tiedossa
                </div>
                <div
                  style={{
                    ...chartStyles.tooltipDataValue,
                    fontWeight: tooltipData.key === 'unknown' ? 600 : 400,
                  }}
                >
                  {formatNumber(tooltipData.unknown)} kpl
                </div>
              </div>
            </div>
          </VisxTooltip>
        )}
      </Box>
    </Box>
  )
}

export default AnalyticsUserChart
