import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import AnalyticsPieChart from '../../../Components/Common/AnalyticsPieChart'
import AnalyticsListChart from '../../../Components/Common/AnalyticsListChart'
import UserAnalyticsGroup from '../../../Components/Common/UserAnalyticsGroup'
import DiscAnalyticsGroup from '../../../Components/Common/DiscAnalyticsGroup'
import ThrowAnalyticsGroup from '../../../Components/Common/ThrowAnalyticsGroup'
import AnalyticsThrowChart from '../../../Components/Common/AnalyticsThrowChart'
import AnalyticsUserChart from '../../../Components/Common/AnalyticsUserChart'
import { Colors } from '../../../Utils/theme'

const Analytics = () => {
  const { analyticsStore } = useStore()
  const { t } = useTranslation()

  const { loading, analytics } = analyticsStore

  useEffect(() => {
    analyticsStore.getAnalytics()
  }, [analyticsStore])

  return (
    <Box sx={styles.container}>
      <Grid
        container
        columns={12}
        columnSpacing='1.5rem'
        rowSpacing='1.5rem'
      >
        <Grid xs={12}>
          <UserAnalyticsGroup
            activeUserCount={analytics?.activeUserCount}
            archivedUserCount={analytics?.archivedUserCount}
            pdgaMemberCount={analytics?.totalPdgaMembers}
            usersWithDiscCount={analytics?.usersWithDiscCount}
            newUsersCount={analytics?.last30DaysNewUsers}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('country_distribution')}
            info={t('country_distribution_info')}
            data={analyticsStore.countryDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('os_distribution')}
            info={t('os_distribution_list_info')}
            data={analyticsStore.osDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('app_version_distribution')}
            info={t('app_version_distribution_info')}
            data={analyticsStore.appVersionDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('skill_level_distribution')}
            info={t('skill_level_distribution_info')}
            data={analyticsStore.skillLevelDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('gender_distribution')}
            info={t('gender_distribution_info')}
            data={analyticsStore.genderDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('os_distribution')}
            info={t('os_distribution_info')}
            data={analyticsStore.osTotalDistribution}
          />
        </Grid>
        {/*
          <Grid md={6} xl={4}>
            <AnalyticsPieChart
              label={t('handedness_distribution')}
              info={t('handedness_distribution_info')}
              data={analyticsStore.handednessDistribution}
            />
          </Grid>
        */}
        <Grid xs={12}>
          <AnalyticsUserChart
            label={t('monthly_user_distribution')}
            info={t('monthly_user_distribution_info')}
            data={analytics?.monthlyUsers}
          />
        </Grid>
        <Divider sx={styles.divider} />
        <Grid xs={12}>
          <DiscAnalyticsGroup
            totalDiscCount={analytics?.totalDiscCount}
            pairedDiscCount={analytics?.pairedDiscCount}
            unpairedDiscCount={analytics?.unpairedDiscCount}
            lostDiscCount={analytics?.lostDiscCount}
            newPairingsCount={analytics?.last30DaysPairings}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('disc_ownership_distribution')}
            info={t('disc_ownership_distribution_info')}
            data={analyticsStore.discOwnershipDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('disc_pairing_distribution')}
            info={t('disc_pairing_distribution_info')}
            data={analyticsStore.discPairings}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('firmware_distribution')}
            info={t('firmware_distribution_info')}
            data={analyticsStore.firmwareDistribution}
          />
        </Grid>
        <Divider sx={styles.divider} />
        <Grid xs={12}>
          <ThrowAnalyticsGroup
            totalThrowCount={analytics?.totalThrowCount}
            activeThrowCount={analytics?.activeThrowCount}
            archivedThrowCount={analytics?.archivedThrowCount}
            averageThrowsPerUser={analytics?.averageThrowsPerUser}
            newThrowsCount={analytics?.last30DaysThrows}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('throw_mode_distribution')}
            info={t('throw_mode_distribution_info')}
            data={analyticsStore.throwModeDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsPieChart
            label={t('throw_type_distribution')}
            info={t('throw_type_distribution_info')}
            data={analyticsStore.throwTypeDistribution}
          />
        </Grid>
        <Grid md={6} xl={4}>
          <AnalyticsListChart
            label={t('throw_firmware_distribution')}
            info={t('throw_firmware_distribution_info')}
            data={analyticsStore.throwFirmwareDistribution}
          />
        </Grid>
        <Grid xs={12}>
          <AnalyticsThrowChart
            label={t('monthly_throws_distribution')}
            info={t('monthly_throws_distribution_info')}
            data={analytics?.monthlyThrows}
          />
        </Grid>
        <Divider sx={{ ...styles.divider, mb: '2rem' }} />
        <Grid xs={12} md={9} xxxl={6}>
          <AnalyticsListChart
            label={t('opened_push_notifications_distribution')}
            info={t('opened_push_notifications_distribution_info')}
            data={analyticsStore.readPushNotifications}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(Analytics)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    pt: '2rem',
    pb: '30rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '30rem',
    // flexGrow: 1
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    p: '1rem 0rem',
    paddingTop: '0.5rem'
  },
  previousButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    justifyContent: 'center',
    mr: '1rem',
  },
  nextButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    ml: '1rem',
  },
  timePeriod: {
    width: {
      xxl: '11rem',
      xl: '11rem',
      xs: '11rem'
    }
  },
  divider: {
    mt: '2rem',
    mb: '0.5rem',
    width: 'calc(100% - 2rem)',
    backgroundColor: Colors.black15,
    height: '1px',
    ml: '1rem'
  }
} as const
