import { createContext, useContext } from 'react'
import SessionStore from './SessionStore'
import AdminUserStore from './AdminUserStore'
import MobileUserStore from './MobileUserStore'
import DiscStore from './DiscStore'
import ThrowStore from './ThrowStore'
import NotificationStore from './NotificationStore'
import EventStore from './EventStore'
import AnalyticsStore from './AnalyticsStore'
import UtilsStore from './UtilsStore'

class RootStore {
  sessionStore
  adminUserStore
  mobileUserStore
  discStore
  throwStore
  notificationStore
  eventStore
  analyticsStore
  utilsStore

  constructor() {
    this.sessionStore = new SessionStore(this)
    this.adminUserStore = new AdminUserStore(this)
    this.mobileUserStore = new MobileUserStore(this)
    this.discStore = new DiscStore(this)
    this.throwStore = new ThrowStore(this)
    this.notificationStore = new NotificationStore(this)
    this.eventStore = new EventStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.utilsStore = new UtilsStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext<RootStore>({} as RootStore)
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext<RootStore>(StoreContext)
