import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
    xxxl: true
  }
}

export enum Colors {
  transparent = 'transparent',
  black = '#000000',
  black90 = 'rgba(0, 0, 0, .9)',
  black80 = 'rgba(0, 0, 0, .8)',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black15 = 'rgba(0, 0, 0, .15)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
  navy = '#0B293F',
  white = '#FFFFFF',
  white90 = 'rgba(255, 255, 255, .9)',
  white80 = 'rgba(255, 255, 255, .8)',
  white70 = 'rgba(255, 255, 255, .7)',
  white60 = 'rgba(255, 255, 255, .6)',
  white50 = 'rgba(255, 255, 255, .5)',
  white40 = 'rgba(255, 255, 255, .4)',
  white30 = 'rgba(255, 255, 255, .3)',
  white20 = 'rgba(255, 255, 255, .2)',
  white10 = 'rgba(255, 255, 255, .1)',
  white05 = 'rgba(255, 255, 255, .05)',
  green = '#D5FB00',
  inputBg = 'rgba(216, 239, 72, .1)',
  heading = '#ffffff',
  heading60 = 'rgba(15, 46, 60, .6)',
  text = '#fff',
  text80 = 'rgba(4, 41, 64, .8)',
  text60 = 'rgba(4, 41, 64, .6)',
  darkText = '#4D4D4D',
  border = '#BDC1C3',
  darkBorder = '#A0ABB1',
  checkboxBg = '#F2F2F2',
  chip = '#E6EBED',
  chipDarker = '#D5E0E4',
  orange = '#F9986E',
  orangeDarker = '#F17E4A',
  orange90 = 'rgba(249, 152, 110, .9)',
  orange80 = 'rgba(249, 152, 110, .8)',
  brandBlue = '#4C0150',
  brandBlue60 = '#4C0150',
  light = '#E9FDFD',
  lightDarker = '#D5FDFD',
  brandPrimary = '#4C0150',
  brandPrimaryDarker = '#10000B',
  brandPrimary90 = 'rgba(76, 1, 80, .9)',
  brandPrimary80 = 'rgba(76, 1, 80, .8)',
  brandPrimary60 = 'rgba(76, 1, 80, .6)',
  brandSecondaryDarker = '#B8047F',
  brandSecondary = '#EC4DB6',
  brandSecondary90 = 'rgba(15, 240, 240, 0.9)',
  brandSecondary80 = 'rgba(15, 240, 240, 0.8)',
  brandSecondary60 = 'rgba(15, 240, 240, 0.6)',
  brandSecondary10 = 'rgba(15, 240, 240, 0.1)',
  cardBackground = '#E7FDFD',
  cardBackground70 = 'rgba(231, 253, 253, .7)',
  violet = '#4C0150',
  lightRed = '#EC4DB6',
  background = '#FF00A4',
  darkBackground = '#4C0150',
  darkBackground80 = 'rgba(76, 1, 80, .8)',
  pink = '#C00186',
  pink90 = 'rgba(192, 1, 134, .9)',
  lightPurple = '#FBCCEB',
  gold = '#FFB800',
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
    },
  },
  palette: {
    primary: {
      main: Colors.darkBackground,
    },
    secondary: {
      main: Colors.darkBackground,
    },
    error: {
      main: Colors.darkBackground,
    },
    background: {
      default: '#nnn',
    },
  },
  typography: {
    fontFamily: 'Anek Latin, sans-serif !important',
    h1: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '2.75rem',
    },
    h2: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.875rem',
    },
    h3: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.5rem',
    },
    h4: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.375rem',
    },
    h5: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.125rem',
    },
    h6: {
      fontFamily: 'Anek Latin, sans-serif !important',
      color: Colors.heading,
      fontSize: '1rem',
    },
    body1: {
      color: Colors.text,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: Colors.transparent,
          pl: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem',
          },
          pr: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.625rem',
          padding: '0.75rem 1.5rem',
          fontSize: '1.125rem',
          fontWeight: 700
        },
        text: {
          color: Colors.white,
        },
      },
    },
    MuiTypography: {},
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            opacity: 1
          },
          '& .MuiInputBase-root.Mui-disabled': {
            WebkitTextFillColor: Colors.text,
            cursor: 'not-allowed',
            backgroundColor: Colors.white20
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: Colors.text,
            cursor: 'not-allowed',
          },
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.625rem',
          '&:hover': {
            backgroundColor: Colors.white10,
            pointer: 'cursor',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        primary: {
          color: Colors.white,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // backgroundColor: Colors.brandSecondary,
          borderRadius: '0.625rem',
        },
        listbox: {
          backgroundColor: Colors.brandSecondary,
        },
        option: {
          backgroundColor: Colors.brandSecondary,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: Colors.background
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2.5rem',
        },
      },
    },
  }
})

export default theme
