import { useRef, useEffect, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import { Pie } from '@visx/shape'
import { Group } from '@visx/group'
import { formatNumber } from '../../Utils/transforms'
import { Colors } from '../../Utils/theme'

// Helper function to convert rem to pixels dynamically
const remToPixels = (rem: number): number => {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
  return rem * rootFontSize
}

// Types
interface DataPoint {
  label: string
  value: number
}

interface AnalyticsPieChartProps {
  data?: DataPoint[]
  label?: string
  info?: string
  sx?: any
  mb?: number | string
  mt?: number | string
  mr?: number | string
  ml?: number | string
  width?: number | string
  height?: number | string
  backgroundColor?: string
}

interface TooltipData {
  label: string
  value: number
  x: number
  y: number
}

// Constants
const SEGMENT_COLORS = [
  '#4C0150',
  '#FBCCEB',
  '#FF76D0',
  '#FFB800'
]
const LabelFontSize = 1.5
const SecondaryLabelFontSize = 0.8125
const LegendLabelFontSize = 1.0625
const LabelFontWeight = 500
const BoldLabelFontWeight = 600
const LabelColor = Colors.white

export default function AnalyticsPieChart(props: AnalyticsPieChartProps) {
  const {
    data = [],
    label,
    info,
  } = props

  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null)
  const [hoveredSegment, setHoveredSegment] = useState<string | null>(null)

  // Calculate total
  const total = useMemo(() => data.reduce((sum, d) => sum + d.value, 0), [data])

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => b.value - a.value)
  }, [data])

  // Update dimensions when container size changes
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect()
        setDimensions({
          width,
          // Account for header
          height: height - remToPixels(5),
        })
      }
    }

    updateDimensions()
    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  const getStyles = () => {
    let containerStyles: any = styles.container
    if (props?.sx) containerStyles = { ...containerStyles, ...props.sx }
    if (props.mb) containerStyles = { ...containerStyles, marginBottom: props.mb }
    if (props.mt) containerStyles = { ...containerStyles, marginTop: props.mt }
    if (props.mr) containerStyles = { ...containerStyles, marginRight: props.mr }
    if (props.ml) containerStyles = { ...containerStyles, marginLeft: props.ml }
    if (props?.width) containerStyles = { ...containerStyles, width: props.width }
    if (props?.height) containerStyles = { ...containerStyles, height: props.height }
    if (props?.backgroundColor) containerStyles = { ...containerStyles, backgroundColor: props.backgroundColor }
    return containerStyles
  }

  const renderChart = () => {
    if (!data?.length || dimensions.width === 0 || dimensions.height === 0) return null

    const width = remToPixels(dimensions.width / 16)
    const height = remToPixels(dimensions.height / 16)
    const radius = Math.min(width, height * 0.8) / 2.5
    const centerX = width / 2
    const centerY = (height * 0.62) / 2

    return (
      <Box sx={styles.chartContainer}>
        <style>
          {`
            @keyframes growRight {
              from {
                transform: scaleX(0);
                transform-origin: left;
              }
              to {
                transform: scaleX(1);
                transform-origin: left;
              }
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            .pie-segment {
              transition: opacity 0.2s ease-out;
              transform-origin: center;
            }
            .pie-segment:hover {
              opacity: 0.8;
            }
          `}
        </style>
        <svg width='100%' height='100%' viewBox={`0 0 ${width} ${height}`}>
          <Group top={centerY} left={centerX}>
            <Pie
              data={sortedData}
              pieValue={d => d.value}
              outerRadius={radius}
              innerRadius={radius * 0.72}
              cornerRadius={0}
              padAngle={0.02}
              padRadius={radius}
            >
              {(pie) => (
                <Group>
                  {pie.arcs.map((arc, i) => {
                    const isHovered = hoveredSegment === arc.data.label
                    
                    return (
                      <g
                        key={`arc-${arc.data.label}`}
                        onMouseEnter={(event) => {
                          const { clientX, clientY } = event
                          setTooltipData({
                            label: arc.data.label,
                            value: arc.data.value,
                            x: clientX,
                            y: clientY
                          })
                          setHoveredSegment(arc.data.label)
                        }}
                        onMouseLeave={() => {
                          setTooltipData(null)
                          setHoveredSegment(null)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <path
                          className='pie-segment'
                          d={pie.path(arc) || ''}
                          fill={SEGMENT_COLORS[i % SEGMENT_COLORS.length]}
                          style={{
                            animation: 'growRight 1s ease-out forwards',
                          }}
                        />
                      </g>
                    )
                  })}
                </Group>
              )}
            </Pie>
            
            {/* Center text */}
            <text
              dy='.125rem'
              textAnchor='middle'
              fill={LabelColor}
              fontSize={remToPixels(LabelFontSize)}
              fontWeight={hoveredSegment ? BoldLabelFontWeight : LabelFontWeight}
              style={{
                fontFamily: 'inherit',
                transition: 'font-weight 0.2s ease-out',
                animation: 'fadeIn 0.5s ease-out forwards',
              }}
            >
              {formatNumber(hoveredSegment 
                ? data.find(d => d.label === hoveredSegment)?.value || 0
                : total)}
            </text>

            {/* Center text for label */}
            <text
              dy='1.5rem'
              textAnchor='middle'
              fill={Colors.lightPurple}
              fontSize={remToPixels(SecondaryLabelFontSize)}
              fontWeight={hoveredSegment ? BoldLabelFontWeight : LabelFontWeight}
              style={{
                fontFamily: 'inherit',
                transition: 'font-weight 0.2s ease-out',
                animation: 'fadeIn 0.5s ease-out forwards',
              }}
            >
              {hoveredSegment ? hoveredSegment : 'Yhteensä'}
            </text>
          </Group>

          {/* Border above legend */}
          <line
            x1={0}
            y1={height - remToPixels(5.25)}
            x2={width}
            y2={height - remToPixels(5.25)}
            stroke={Colors.background}
            strokeWidth={1}
          />

          {/* Legend at bottom */}
          <Group top={height - remToPixels(3.25)}>
            {sortedData.map((d, i) => {
              // Divide chart into x equal sections regardless of data length
              const sectionWidth = width / (data.length || 1)
              // Position each item at the center of its section
              const xPosition = (i * sectionWidth) + (sectionWidth / 2.1)
              
              return (
                <Group
                  key={d.label}
                  left={0}
                  onMouseEnter={() => setHoveredSegment(d.label)}
                  onMouseLeave={() => setHoveredSegment(null)}
                  style={{ cursor: 'pointer' }}
                >
                  <g transform={`translate(${xPosition}, 0)`}>
                    <circle
                      cx={-remToPixels(16/15)}
                      cy={0}
                      r={remToPixels(0.5)}
                      fill={SEGMENT_COLORS[i % SEGMENT_COLORS.length]}
                    />
                    <text
                      x={0}
                      y={remToPixels(5 / 16)}
                      fill={LabelColor}
                      fontSize={remToPixels(LegendLabelFontSize)}
                      fontWeight={hoveredSegment === d.label ? BoldLabelFontWeight : LabelFontWeight}
                      style={{ 
                        fontFamily: 'inherit',
                        transition: 'font-weight 0.2s ease-out'
                      }}
                      textAnchor='start'
                    >
                      {d.label}
                    </text>
                  </g>
                </Group>
              )
            })}
          </Group>
        </svg>

        {/*tooltipData && (
          <Fade in={true} timeout={200}>
            <Paper elevation={3} sx={styles.hoverCard}>
              <Typography variant='subtitle2' sx={styles.hoverTitle}>
                {tooltipData.value}
              </Typography>
            </Paper>
          </Fade>
        )*/}
      </Box>
    )
  }

  return (
    <Box ref={containerRef} sx={getStyles()}>
      <Box sx={styles.header}>
        <Box sx={styles.label}>{label}</Box>
        <Box sx={styles.info}>{info || '-'}</Box>
      </Box>
      {renderChart()}
    </Box>
  )
}

const styles = {
  container: {
    height: '26rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '0.625rem',
    backgroundColor: Colors.brandSecondaryDarker,
    overflow: 'hidden',
  },
  chartContainer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  header: {
    p: {
      xs: '1.125rem 1.5rem',
      // xs: '0.75rem',
    },
    mb: '0.5rem'
  },
  label: {
    fontSize: {
      xs: '1.125rem',
      // xs: '1rem'
    },
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
  },
  info: {
    fontSize: {
      xs: '0.875rem',
      // xs: '0.75rem'
    },
    fontWeight: 400,
    color: Colors.white,
  },
  hoverCard: {
    position: 'fixed',
    transform: 'translate(-50%, -100%)',
    px: '0.75rem',
    py: '0.5rem',
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  hoverTitle: {
    color: Colors.white,
    fontWeight: 700,
    textAlign: 'center',
  },
} as const