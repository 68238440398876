import apisauce from 'apisauce'
import { Event, EventParticipant } from '../Types/events'
import { UUID } from '../Types/common'

// TODO: TEMPORARILY AUTHENTICATE WITH API KEY.
// THIS SHOULD BE REMOVED ONCE ACTUAL AUTHENTICATION IS IMPLEMENTED.
const API_KEY = 'f6ecdfe1-098a-4a09-b186-6e40147c7293'
const withApiKey = (url: string) => `${url}?api_key=${API_KEY}`

const ApiUrls = {
  Local: `http://127.0.0.1:1337/v1/admin/`,
  Stage: 'https://gameproofer-api-staging.peanutsgroup.com/v1/admin/',
  Production: 'https://gameproofer-api.peanutsgroup.com/v1/admin/',
}

let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

const create = (baseURL = ApiUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: {},
    timeout: 15000,
    withCredentials: true,
  })

  // Public endpoints
  const login = (body: any) => api.post('account/login', body)
  const requestPasswordReset = (body: any) => api.post('account/password-reset', body)
  const getPasswordResetRequest = (code: string) => api.get(`account/password-reset-requests/${code}`)
  const completePasswordReset = (body: any) => api.put('account/password-reset', body)
  const getInvite = (code: string) => api.get(`account/invites/${code}`)
  const verifyAccount = (body: any) => api.put('account/verify', body)

  // Authorization required
  const logout = () => api.put('account/logout')
  const keepAlive = () => api.get('session/keep-alive')
  const getMe = () => api.get('account/me')
  const updateMe = (body: any) => api.put('account/me', body)
  const updateMyProfilePhoto = (body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post('account/me/profile-photo', formData, options)
  }
  const deleteMyProfilePhoto = () => api.delete('account/me/profile-photo')
  const searchForLocation = (location: string) => api.get(withApiKey('utilities/locations/search'), { location })
  const archiveMe = () => api.delete('account/me')

  // Admin users
  const getAdminUsers = (params?: any) => api.get('users/admin', params || {})
  const getAdminUser = (userId: number) => api.get(`users/admin/${userId}`)
  const updateAdminUser = (userId: number, body: any) => api.put(`users/admin/${userId}`, body)
  const archiveAdminUser = (userId: number) => api.delete(`users/admin/${userId}`)
  const activateAdminUser = (userId: number) => api.put(`users/admin/${userId}/activate`)
  const createAdminUser = (body: any) => api.post('users/admin', body)
  const updateAdminUserProfilePhoto = (userId: number, body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`users/admin/${userId}/profile-photo`, formData, options)
  }

  // Mobile users
  const getMobileUsers = (params?: any) => api.get('users/mobile', params || {})
  const getMobileUserOptions = () => api.get('users/mobile/options')
  const getMobileUser = (userId: number) => api.get(`users/mobile/${userId}`)
  const updateMobileUser = (userId: number, body: any) => api.put(`users/mobile/${userId}`, body)
  const archiveMobileUser = (userId: number) => api.delete(`users/mobile/${userId}`)
  const activateMobileUser = (userId: number) => api.put(`users/mobile/${userId}/activate`)

  // Discs
  const getDiscs = (params?: any) => api.get('discs', params)
  const getDisc = (id: number) => api.get(`discs/${id}`)
  const updateDisc = (id: number, body: any) => api.put(`discs/${id}`, body)
  const unlockDisc = (id: number) => api.put(`discs/${id}/unlock`)
  const archiveDisc = (id: number) => api.delete(`discs/${id}`)
  const transferDiscOwnership = (discId: number, userId: number) => api.put(`discs/${discId}/transfer`, { userId })

  // Throws
  const getThrows = (params?: any) => api.get('throws', params)
  const getDiscThrows = (discId: number, params?: any) => api.get(`discs/${discId}/throws`, params)
  const getThrow = (throwId: number) => api.get(`throws/${throwId}`)
  const archiveThrow = (throwId: number) => api.delete(`throws/${throwId}`)

  // Notifications
  const getNotifications = (params?: any) => api.get('notifications', params)
  const getNotification = (id: number) => api.get(`notifications/${id}`)
  const createNotification = (body: any) => api.post('notifications', body)
  const updateNotification = (id: number, body: any) => api.put(`notifications/${id}`, body)
  const uploadNotificationPhoto = (id: number, file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`notifications/photo`, formData, options)
  }
  const archiveNotification = (id: number) => api.delete(`notifications/${id}`)

  // Events
  const getEvents = (params?: any) => api.get<Event[]>('events', params || {})
  const getEvent = (eventUuid: UUID) => api.get<Event>(`events/${eventUuid}`)
  const getEventThrows = (eventUuid: UUID) => api.get(`events/${eventUuid}/throws`)
  const getEventParticipants = (eventUuid: UUID) =>
    api.get<EventParticipant[]>(`events/${eventUuid}/participants`)
  const setEventActiveThrower = (eventUuid: UUID, mobileUserId: number) =>
    api.post<undefined>(`events/${eventUuid}/active-thrower/${mobileUserId}`)
  const removeEventActiveThrower = (eventUuid: UUID) =>
    api.delete<undefined>(`events/${eventUuid}/active-thrower`)
  const createEvent = (body: any) => api.post<Event>('events', body)
  const updateEvent = (eventUuid: UUID, body: Partial<Event>) => api.put<Event>(`events/${eventUuid}`, body)
  const archiveEvent = (eventUuid: UUID) => api.delete(`events/${eventUuid}`)
  const updateEventManager = (eventUuid: UUID, mobileUserEmail: string) =>
    api.post(`events/${eventUuid}/event-manager/${mobileUserEmail}`)
  const removeEventManager = (eventUuid: UUID) => api.delete(`events/${eventUuid}/event-manager`)
  const updateEventPhoto = (eventUuid: UUID, file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post<any>(`events/${eventUuid}/photo`, formData, options)
  }
  const archiveEventPhoto = (eventUuid: UUID) => api.delete(`events/${eventUuid}/photo`)
  const archiveEventThrow = (eventUuid: UUID, throwId: number) =>
    api.delete(`events/${eventUuid}/throws/${throwId}`)

  // Analytics
  const getAnalytics = (params?: any) => api.get('analytics', params)

  return {
    logout,
    login,
    keepAlive,
    requestPasswordReset,
    getPasswordResetRequest,
    completePasswordReset,
    getInvite,
    verifyAccount,
    getMe,
    updateMe,
    updateMyProfilePhoto,
    deleteMyProfilePhoto,
    searchForLocation,
    archiveMe,
    getAdminUsers,
    getAdminUser,
    updateAdminUser,
    archiveAdminUser,
    activateAdminUser,
    updateAdminUserProfilePhoto,
    createAdminUser,
    getMobileUsers,
    getMobileUserOptions,
    getMobileUser,
    updateMobileUser,
    archiveMobileUser,
    activateMobileUser,
    getDiscs,
    getDisc,
    updateDisc,
    unlockDisc,
    archiveDisc,
    transferDiscOwnership,
    getThrows,
    getDiscThrows,
    getThrow,
    archiveThrow,
    getNotifications,
    getNotification,
    createNotification,
    updateNotification,
    uploadNotificationPhoto,
    archiveNotification,
    getEvents,
    getEvent,
    getEventThrows,
    createEvent,
    updateEvent,
    archiveEvent,
    getEventParticipants,
    setEventActiveThrower,
    removeEventActiveThrower,
    updateEventManager,
    removeEventManager,
    updateEventPhoto,
    archiveEventPhoto,
    archiveEventThrow,
    getAnalytics,
  }
}

const Api = create()

export default Api
