import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { useDropzone } from 'react-dropzone'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Input, Select } from '../../../Components'
import Map from '../../../Components/Common/Map'
import Checkbox from '../../../Components/Common/Checkbox'
import ButtonBase from '@mui/material/ButtonBase'
import TimeInput from '../../../Components/Common/TimeInput'
import TextEditor from '../../../Components/Common/TextEditor'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { CountryOptions } from '../../../Utils/constants'
import {
  EventParticipant,
  Event as EventType,
  SkillLevel,
  ThrowDataItem,
  Gender
} from '../../../Types/events'
import { Colors } from '../../../Utils/theme'
import { showMessage } from '../../../Utils/message'

enum Tab {
  Actions = 'actions',
  EventInformation = 'event_information',
}

const SkillLevels: SkillLevel[] = [SkillLevel.All, SkillLevel.Pro, SkillLevel.Amateur]
const Genders: Gender[] = [Gender.All, Gender.Male, Gender.Female]
const ThrowDataItems: ThrowDataItem[] = Object.values(ThrowDataItem)

const Event = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()

  const { eventStore, utilsStore } = useStore()
  const { event, eventParticipants } = eventStore

  const [tab, setTab] = useState(Tab.Actions)
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState<any>(null)
  const [startTime, setStartTime] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)
  const [description, setDescription] = useState('')
  const [descriptionHtml, setDescriptionHtml] = useState('')
  const [instructions, setInstructions] = useState('')
  const [instructionsBeforeEvent, setInstructionsBeforeEvent] = useState('')
  const [instructionsDuringEvent, setInstructionsDuringEvent] = useState('')
  const [place, setPlace] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [longitude, setLongitude] = useState('')
  const [latitude, setLatitude] = useState('')
  const [skillLevel, setSkillLevel] = useState<SkillLevel[]>([])
  const [gender, setGender] = useState<Gender[]>([])
  const [throwData, setThrowData] = useState<ThrowDataItem[]>([])
  const [activeThrower, setActiveThrower] = useState<EventParticipant | null>(null)
  const [customActiveThrowerName, setCustomActiveThrowerName] = useState<string>('')
  const [eventManagerEmail, setEventManagerEmail] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [hiddenInApp, setHiddenInApp] = useState(false)

  const openActionConfirmation = () => setShowActionConfirmation(true)
  const closeActionConfirmation = () => setShowActionConfirmation(false)

  useEffect(() => {
    const eventUuid = params?.eventUuid ?? null
    if (eventUuid) {
      eventStore.getEvent(eventUuid)
      eventStore.getEventParticipants(eventUuid)
    }
  }, [eventStore, params?.eventUuid])

  useEffect(() => {
    if (event) {
      setName(event?.name ?? '')
      setStartDate(DateTime.fromISO(event?.startsAt).toFormat('yyyy-MM-dd'))
      setStartTime(DateTime.fromISO(event?.startsAt).toFormat('HH:mm'))
      setEndDate(DateTime.fromISO(event?.endsAt).toFormat('yyyy-MM-dd'))
      setEndTime(DateTime.fromISO(event?.endsAt).toFormat('HH:mm'))
      setDescription(event?.description ?? '')
      setDescriptionHtml(event?.descriptionHtml ?? '')
      setInstructions(event?.instructions ?? '')
      setInstructionsBeforeEvent(event?.instructionsBeforeEvent ?? '')
      setInstructionsDuringEvent(event?.instructionsDuringEvent ?? '')
      setPlace(event?.place ?? '')
      setStreetAddress(event?.streetAddress ?? '')
      setPostalCode(event?.postalCode ?? '')
      setCity(event?.city ?? '')
      setCountry(event?.country ?? '')
      setLongitude(event?.longitude ? event?.longitude.toString() : '')
      setLatitude(event?.latitude ? event?.latitude.toString() : '')
      setSkillLevel(event?.skillLevel ?? [])
      setGender(event?.gender ?? [])
      setThrowData(event?.throwData ?? [])
      setActiveThrower(event?.activeThrower ?? null)
      setCustomActiveThrowerName(event?.customActiveThrowerName ?? '')
      setEventManagerEmail(event?.eventMobileUser?.email ?? '')
      setHiddenInApp(event?.hiddenInApp)
    }
  }, [event])

  const openActions = () => setTab(Tab.Actions)
  const openInformation = () => setTab(Tab.EventInformation)
  const toggleHiddenInApp = () => setHiddenInApp(!hiddenInApp)
  const toThrows = () => navigate(`/events/${params.eventUuid}/throws`)

  const updateActiveThrower = (value: number) => {
    setActiveThrower(eventParticipants.find((participant) => participant.id === value) ?? null)
    setCustomActiveThrowerName('')
  }

  const updateCustomActiveThrower = (value: string) => {
    setCustomActiveThrowerName(value)
    setActiveThrower(null)
  }

  const copyPublicLink = () => {
    if (event?.publicLink) {
      navigator.clipboard.writeText(event.publicLink)
      showMessage(t('copied_to_clipboard'))
    }
  }

  const save = () => {
    const startsAt = DateTime
      .fromFormat(`${startDate} ${startTime}`, `yyyy-MM-dd HH:mm`)
      .toISO() ?? undefined
    const endsAt = DateTime
      .fromFormat(`${endDate} ${endTime}`, `yyyy-MM-dd HH:mm`)
      .toISO() ?? undefined
    const updatedEvent: Partial<EventType> = {
      name,
      startsAt,
      endsAt,
      description,
      descriptionHtml,
      instructions,
      instructionsBeforeEvent,
      instructionsDuringEvent,
      place,
      streetAddress,
      postalCode,
      city,
      country,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      skillLevel,
      gender,
      throwData,
      hiddenInApp
    }

    eventStore.editEvent(params?.eventUuid || '', updatedEvent)
  }

  const handleActiveThrowerChange = () => {
    const isCustomNameChanged = customActiveThrowerName !== event?.customActiveThrowerName
    const isActiveThrowerChanged = activeThrower?.id !== event?.activeThrowerId

    if (isCustomNameChanged && params.eventUuid && customActiveThrowerName) {
      eventStore.editEvent(params.eventUuid, { customActiveThrowerName })
    } else if (isActiveThrowerChanged && params.eventUuid && activeThrower?.id) {
      eventStore.setEventActiveThrower(params.eventUuid, activeThrower.id)
    }
  }

  const handleEventManagerChange = () => {
    if (params.eventUuid) {
      if (eventManagerEmail.length) {
        eventStore.setEventManager(params.eventUuid, eventManagerEmail)
      } else {
        eventStore.removeEventManager(params.eventUuid)
      }
    }
  }

  const handleSkillLevelChange = (value: SkillLevel) => {
    if (skillLevel.includes(value)) {
      setSkillLevel(skillLevel.filter((level) => level !== value))
    } else {
      setSkillLevel([...skillLevel, value])
    }
  }

  const handleGenderChange = (value: Gender) => {
    if (gender.includes(value)) {
      setGender(gender.filter((gender) => gender !== value))
    } else {
      setGender([...gender, value])
    }
  }

  const handleThrowDataChange = (value: ThrowDataItem) => {
    if (throwData.includes(value)) {
      setThrowData(throwData.filter((dataItem) => dataItem !== value))
    } else {
      setThrowData([...throwData, value])
    }
  }

  const handleDownloadQRCode = (filename: string, code?: string | null) => {
    if (code) {
      QRCode.toDataURL(code, { errorCorrectionLevel: 'H', width: 500 }, (err, url) => {
        if (err) {
          console.error(err)
        }
        const a = document.createElement('a')
        a.href = url
        a.download = `${filename}.png`
        a.click()
      })
    }
  }

  const downloadParticipateQRCode = () => {
    handleDownloadQRCode('qr-code-participate', event?.qrCodes?.participate)
  }
  const downloadActiveThrowerQRCode = () => {
    handleDownloadQRCode('qr-code-active-thrower', event?.qrCodes?.activeThrower)
  }

  const formatParticipantName = (participant: EventParticipant) =>
      `${participant?.firstName || ''} ${participant?.lastName || ''}`?.trim() || 'Ei nimeä'


  const searchForLocation = async () => {
    let address = `${streetAddress}, ${postalCode} ${city}`
    if (country) {
      address = `${address}, ${country}`
    }
    const location: any = await utilsStore.searchForLocation(address)
    if (location && location?.latitude && location?.longitude) {
      setLatitude(location.latitude)
      setLongitude(location.longitude)
    }
  }

  const onUploadEventPhoto = useCallback((files: any) => {
    if (params?.eventUuid && files && files?.length) {
      const file = files?.[0]
      eventStore.updateEventPhoto(
        params?.eventUuid,
        file
      )
    }
  }, [])

  const deletePhoto = () => {
    if (params?.eventUuid) {
      eventStore.archiveEventPhoto(
        params.eventUuid,
        closeActionConfirmation
      )
    }
  }

  const drop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadEventPhoto
  })

  const renderSkillLevelOptions = () => {
    return SkillLevels.map((value) => (
      <Checkbox
        key={value}
        label={t(`skill_level_option.${value}`)}
        checked={skillLevel.includes(value)}
        onChange={() => handleSkillLevelChange(value)}
      />
    ))
  }

  const renderGenderOptions = () => {
    return Genders.map((value) => (
      <Checkbox
        key={value}
        label={t(`gender_option.${value}`)}
        checked={gender.includes(value)}
        onChange={() => handleGenderChange(value)}
      />
    ))
  }

  const renderThrowDataOptions = () => {
    return ThrowDataItems.map((value, index: number) => (
      <Checkbox
        key={value}
        label={t(`throw_data_item.${value}`)}
        checked={throwData.includes(value)}
        onChange={() => handleThrowDataChange(value)}
        width='33.3333%'
        mb={index < 9 ? '0.75rem' : '0'}
      />
    ))
  }

  const renderCity = () => {
    /*
    if (country === 'Suomi') {
      return (
        <Select
          label={t('city')}
          options={FinnishMunicipalityOptions}
          value={city}
          onChange={setCity}
        />
      )
    } else {
      return <Input label={t('city')} value={city} onChange={setCity} />
    }
    */
    return <Input label={t('city')} value={city} onChange={setCity} />
  }

  const renderMap = () => {
    if (latitude && longitude && parseFloat(latitude) && parseFloat(longitude)) {
      return (
        <>
          <Typography variant='body2' sx={styles.label}>{t('on_map')}</Typography>
          <Map location={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
        </>
      )
    }
    return null
  }

  const renderPhoto = () => {
    if (event?.photo) {
      return <Box component='img' sx={styles.photo} src={event.photo} />
    }
    return (
      <Box sx={styles.infoContainer}>
        <Typography
          variant='body2'
          fontSize='1rem'
          fontWeight={600}
          color={Colors.white}
          textAlign='center'
          pl='1rem'
          pr='1rem'
        >
          {t('upload_event_photo_info')}
        </Typography>
      </Box>
    )
  }

  const renderPhotoAction = () => {
    if (event?.photo) {
      return (
        <>
          <Button
            text={t('change_photo')}
            onClick={drop.open}
            width='100%'
            variant='secondary'
            mb='0.75rem'
          />
          <Button
            text={t('delete_photo')}
            onClick={openActionConfirmation}
            width='100%'
            variant='text'
          />
        </>
      )
    }
    return (
      <Button
        text={t('upload_photo')}
        onClick={drop.open}
        width='100%'
        variant='secondary'
      />
    )
  }

  const renderConfirmationDialog = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t('delete_photo')}
          content={t('delete_photo_confirmation')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={deletePhoto}
          actionText={t('delete')}
        />
      )
    }
    return null
  }
  
  const renderTabContent = () => {
    if (tab === Tab.Actions) {
      return (
        <>
          <Typography variant='h2' mb='1.5rem' mt='2rem'>
            {t('event_text.active_thrower')}
          </Typography>
          <Box sx={styles.formContainer}>
            <Typography variant='body1' fontSize='0.875rem' mb='1.125rem'>
              {t('event_text.select_active_thrower_info')}
            </Typography>
            <Select
              label={t('event_text.event_participants')}
              placeholder={
                !activeThrower && !customActiveThrowerName ?
                  t('event_text.select_active_thrower') :
                  undefined
              }
              options={eventParticipants.map((participant) => ({
                ...participant,
                label: formatParticipantName(participant)
              }))}
              isOptionEqualToValue={(option: any, value: any) => option.id === value?.id}
              getOptionLabel={(option: any) => formatParticipantName(option)}
              value={activeThrower}
              onChange={updateActiveThrower}
            />
            <Input
              label={t('event_text.custom_active_thrower_name')}
              value={customActiveThrowerName}
              onChange={updateCustomActiveThrower}
              placeholder={
                !activeThrower && !customActiveThrowerName
                  ? t('event_text.add_custom_active_thrower_name')
                  : undefined
              }
              mt='1.5rem'
            />
            <Box display='flex' flexDirection='row' justifyContent='space-between' mt='0.5rem'>
              <Button
                text={t('event_text.change_active_thrower')}
                onClick={handleActiveThrowerChange}
                backgroundColor={Colors.brandPrimary}
                sx={styles.button}
                disabled={!activeThrower && !customActiveThrowerName}
              />
              <Button
                text={t('event_text.remove_active_thrower')}
                onClick={() => params.eventUuid && eventStore.removeEventActiveThrower(params.eventUuid)}
                sx={styles.button}
                variant='secondary'
              />
            </Box>
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('event_text.event_manager')}
          </Typography>
          <Box sx={styles.formContainer}>
            <Typography variant='body1' fontSize='0.875rem' mb='1.125rem'>
              {t('event_text.event_manager_info')}
            </Typography>
            <Input
              label={t('event_text.event_manager_label')}
              value={eventManagerEmail}
              onChange={setEventManagerEmail}
            />
            <Button
              text={t('event_text.change_event_manager')}
              onClick={handleEventManagerChange}
              backgroundColor={Colors.brandPrimary}
              sx={styles.button}
            />
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('event_text.public_link')}
          </Typography>
          <Box sx={styles.formContainer}>
            <Input
              label={t('link')}
              value={event?.publicLink ?? ''}
              disabled
            />
            <Button
              text={t('copy_link')}
              onClick={copyPublicLink}
              backgroundColor={Colors.brandPrimary}
              sx={{ ...styles.button, maxWidth: '13rem' }}
            />
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('event_text.load_qr_codes_title')}
          </Typography>
          <Box sx={styles.formContainer} mb='4rem'>
            {event?.qrCodes && (
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Button
                  text={t('event_text.participate_qr')}
                  backgroundColor={Colors.brandPrimary}
                  width='48%'
                  onClick={downloadParticipateQRCode}
                  icon='download'
                />
                <Button
                  text={t('event_text.active_thrower_qr')}
                  backgroundColor={Colors.brandPrimary}
                  width='48%'
                  onClick={downloadActiveThrowerQRCode}
                  icon='download'
                />
              </Box>
            )}
          </Box>
        </>
      )
    } else if (tab === Tab.EventInformation) {
      return (
        <>
          <Typography variant='h2' mb='1.5rem' mt='2rem'>{t('event_information')}</Typography>
          <Box sx={styles.formContainer}>
            <Input label={t('event_name')} value={name} onChange={setName} mb='1.5rem' />
            <Box sx={styles.row}>
              <Box sx={styles.timesContainer}>
                <Input
                  label={t('starts_at')}
                  value={startDate}
                  onChange={setStartDate}
                  width='10rem'
                  type='date'
                />
                <TimeInput
                  value={startTime}
                  onChange={setStartTime}
                  width='5.625rem'
                  mt='1.925rem'
                  mr='1rem'
                />
              </Box>
              <Box sx={styles.separator}>-</Box>
              <Box sx={styles.timesContainer}>
                <Input
                  label={t('ends_at')}
                  value={endDate}
                  onChange={setEndDate}
                  width='10rem'
                  type='date'
                  ml='1rem'
                />
                <TimeInput
                  value={endTime}
                  onChange={setEndTime}
                  width='5.625rem'
                  mt='1.925rem'
                />
              </Box>
            </Box>
            <TextEditor
              label={t('description')}
              value={descriptionHtml}
              onChange={setDescriptionHtml}
            />
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('event_text.scoreboard_settings')}
          </Typography>
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Box display='flex' flexDirection='column' mr='2rem'>
                <Typography variant='body1' sx={styles.label}>
                  {t('event_text.skill_level_title')}
                </Typography>
                <Box sx={styles.optionsContainer}>
                  {renderSkillLevelOptions()}
                </Box>
              </Box>
              <Box display='flex' flexDirection='column'>
                <Typography variant='body1' sx={styles.label}>
                  {t('event_text.gender_title')}
                </Typography>
                <Box sx={styles.optionsContainer}>
                  {renderGenderOptions()}
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' sx={styles.label}>
                {t('event_text.throw_data_title')}
              </Typography>
              <Box sx={{ ...styles.optionsContainer, width: '43rem', justifyContent: 'flex-start' }}>
                {renderThrowDataOptions()}
              </Box>
            </Box>
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>{t('location')}</Typography>
          <Box sx={styles.formContainer}>
            <Input
              label={t('event_place')}
              value={place}
              onChange={setPlace}
              mb='1.5rem'
            />
            <Input
              label={t('street_address')}
              value={streetAddress}
              onChange={setStreetAddress}
              mb='1.5rem'
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={postalCode}
                onChange={setPostalCode}
                maxWidth='12rem'
                mr='2rem'
              />
              {renderCity()}
            </Box>
            <Select
              label={t('country')}
              options={CountryOptions}
              value={country}
              onChange={setCountry}
              maxWidth='24rem'
              mb='1.5rem'
            />
            <Box sx={{ ...styles.row, justifyContent: 'flex-start' }}>
              <Input
                label={t('latitude')}
                value={latitude}
                onChange={setLatitude}
                maxWidth='9rem'
                mr='2rem'
              />
              <Input
                label={t('longitude')}
                value={longitude}
                onChange={setLongitude}
                maxWidth='9rem'
                mr='2rem'
              />
              <Button
                text={t('search_for_location')}
                onClick={searchForLocation}
                disabled={!streetAddress || !postalCode || !city}
                variant='secondary'
                width='11rem'
                mt='1.75rem'
              />
            </Box>
            {renderMap()}
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('instructions_before_event')}
          </Typography>
          <Box sx={styles.formContainer}>
            <TextEditor
              label={t('instruction_text')}
              value={instructionsBeforeEvent}
              onChange={setInstructionsBeforeEvent}
            />
          </Box>
          <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
            {t('instructions_during_event')}
          </Typography>
          <Box sx={styles.formContainer}>
            <TextEditor
              label={t('instruction_text')}
              value={instructionsDuringEvent}
              onChange={setInstructionsDuringEvent}
            />
          </Box>
        </>
      )
    }
    return null
  }

  if (!event || event?.uuid !== params?.eventUuid) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='2rem'>{event?.name}</Typography>
        <Box sx={styles.tabs}>
          <ButtonBase
            onClick={openActions}
            sx={tab === Tab.Actions ? styles.selectedTab : styles.tab}
          >
            {t('actions')}
          </ButtonBase>
          <ButtonBase
            onClick={openInformation}
            sx={tab === Tab.EventInformation ? styles.selectedTab : styles.tab}
          >
            {t('information')}
          </ButtonBase>
        </Box>
        {renderTabContent()}
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            backgroundColor={Colors.brandPrimary}
            width='100%'
            mb='0.5rem'
          />
        </Box>
        <Box sx={{ ...styles.actionsContainer, alignItems: 'flex-start' }}>
          <Typography variant='body1' fontSize='0.875rem' mb='1.125rem'>
            {t('event_visibility_info')}
          </Typography>
          <Checkbox
            label={t('visible_to_all_users')}
            checked={!hiddenInApp}
            onChange={toggleHiddenInApp}
            mb='0.5rem'
          />
        </Box>
        <Box sx={styles.actionsContainer}>
          {renderPhoto()}
          <Box {...drop.getRootProps()} width='100%'>
            <input {...drop.getInputProps()} />
            {renderPhotoAction()}
          </Box>
        </Box>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('view_throws')}
            onClick={toThrows}
            backgroundColor={Colors.brandPrimary}
            width='100%'
            mb='0.5rem'
          />
        </Box>
      </Box>
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(Event)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '10rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem',
      xs: '1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mt: '2.5rem',
    mb: '1rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: Colors.white
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '1rem',
    fontWeight: 600
  },
  optionsContainer: {
    width: '20.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: Colors.black20,
    borderRadius: '0.625rem',
    p: '0.825rem 1.125rem',
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '20rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
  link: {
    textDecoration: 'underline',
    alignSelf: 'center',
    ':hover': {
      opacity: 0.8,
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: Colors.brandSecondary,
    borderRadius: '0.625rem',
    padding: '1rem',
    mb: '1rem',
  },
  infoButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1rem 1.25rem',
    mb: '1rem',
    ':hover': {
      backgroundColor: Colors.cardBackground70,
    },
  },
  photo: {
    width: '100%',
    height: '10rem',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    mb: '1rem',
  },
  activeDot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    backgroundColor: '#4DB974',
    marginLeft: '0.5rem',
  },
  inactiveDot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    backgroundColor: '#FAC37F',
    marginLeft: '0.5rem',
  },
  tabs: {
    width: '24rem',
    height: '3.125rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.brandSecondaryDarker,
    color: Colors.text,
    borderRadius: '0.3125rem',
    padding: '0.5rem'
  },
  tab: {
    width: '11.125rem',
    height: '100%',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    transition: 'background-color 0.3s',
    padding: '0 1rem',
    borderRadius: '0.3125rem',
    '&:hover': {
      color: Colors.text,
      backgroundColor: Colors.black20
    },
  },
  selectedTab: {
    width: '11.125rem',
    height: '100%',
    fontSize: '1rem',
    fontWeight: 600,
    transition: 'background-color 0.3s',
    padding: '0 1rem',
    backgroundColor: Colors.brandPrimary,
    color: Colors.text,
    borderRadius: '0.3125rem',
    '&:hover': {
      backgroundColor: Colors.brandPrimary
    }
  },
  button: {
    minWidth: {
      xs: '48%',
    },
    maxWidth: {
      lg: '48%',
      xs: '20rem',
    },
    mt: '1.5rem',
    fontSize: {
      lg: '1rem',
      xs: '0.8rem'
    }
  }
} as const
